<template>
	<form>
		<fieldset>
			<legend>FERRAMENTA DE CLAREZA</legend>

			<div class="form-group">
				<label>O QUE VOCÊ VEIO BUSCAR NESTA IMERSÃO?</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="clareza1"
					@change="addToPoll('clareza1', clareza1)"
				></textarea>
			</div>

			<div class="form-group">
				<label
					>O QUE PRECISA ACONTECER DURANTE ESTA IMERSÃO PARA ELA SER
					INCRÍVEL PARA VOCÊ?</label
				>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="clareza2"
					@change="addToPoll('clareza2', clareza2)"
				></textarea>
			</div>

			<div class="form-group">
				<label
					>O QUE VOCÊ SE DISPÕE A FAZER PARA QUE ISSO ACONTEÇA?</label
				>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="clareza3"
					@change="addToPoll('clareza3', clareza3)"
				></textarea>
			</div>

			<div class="form-group">
				<label>POR QUE ISSO É IMPORTANTE PARA VOCÊ?</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="clareza4"
					@change="addToPoll('clareza4', clareza4)"
				></textarea>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			clareza1: "",
			clareza2: "",
			clareza3: "",
			clareza4: "",
		};
	},
	created() {
		for (var k in this._data) {
			if (k.startsWith("clareza")) this.carregaRespostas(k, true);
		}
	},
};
</script>

<style>
</style>
