<template>
	<div class="pagina-luisa">
		<div class="container" v-if="turmaList">
			<div class="panel">
				<div class="panel-body text-center center-middle">
					<div>
						<h1>
							Identificados que você está inscrita em alguns
							eventos. Selecione o que deseja interagir
						</h1>

						<button
							v-for="turm in turmaList"
							:key="turm.id"
							class="btn btn-primary"
							@click="getTurma(turm.id)"
						>
							{{ turm.nome }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="container" v-if="turma">
			<div v-if="!turmaIniciada" class="panel panel-default counter">
				<div class="panel-body">
					<h1 class="text-center">
						Faltam <app-counter :inicio="turma.inicio" /> para a
						{{ turma.nome }}
					</h1>
				</div>
			</div>

			<div class="panel mensagem">
				<div class="panel-body text-center center-middle">
					<div>
						<h1>Seja bem vinda!</h1>
						<h2>
							Tudo o que você precisa para ter uma experiência
							completa e incrível está aqui
						</h2>
						<button
							v-if="turmaIniciada"
							class="btn btn-primary"
							@click="startEvento()"
						>
							Vamos começar?
						</button>

						<button
							v-if="!turmaIniciada"
							class="btn btn-primary"
							@click="goToDuvida()"
						>
							Fique por dentro de tudo
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Contador from "@/components/UI/TimeCounter.vue";

export default {
	data() {
		return { turma: null, turmaList: null };
	},
	methods: {
		getMatriculas() {
			this.$http.get("matricula").then((response) => {
				var length = response.body.length;
				if (length == 0)
					var returnMessage = {
						type: "alert-warning",
						mensagem:
							"Aparentemente você não está inscrita em nenhum evento.",
					};
				this.$emit("showMessage", returnMessage);
				if (length == 1)
					this.getTurma(response.body[length - 1].turma.id);
				if (length > 1)
					this.turmaList = response.body.map((item) => item.turma);
			});
		},
		getTurmas() {
			this.turmaList = null;
			this.$http.get("turma").then((response) => {
				this.turmaList = response.body;
			});
		},
		getTurma(idTurma) {
			this.turmaList = null;
			this.$http
				.get(`turma/${idTurma}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.turma = data;
				});
		},
		startEvento() {
			this.$http.get(`pagina/${this.turma.id}/1`).then((response) => {
				if (response.body.liberada == 1)
					this.$router.push(`/ferramenta/${this.turma.id}`);
				else {
					var returnMessage = {
						type: "alert-warning",
						mensagem:
							"Logo logo faremos essa ferramenta. Aguarde as instruções da Luiza.",
					};
					this.$emit("showMessage", returnMessage);
				}
			});
		},
		goToDuvida() {
			this.$router.push("/duvida");
		},
	},
	computed: {
		turmaIniciada() {
			var diferenca =
				new Date(this.turma.inicio).getTime() - new Date().getTime();
			return diferenca < 0;
		},
	},
	created() {
		if (this.isAdm) this.getTurmas();
		else this.getMatriculas();
	},
	components: {
		appCounter: Contador,
	},
};
</script>

<style scoped>
h1 {
	font-size: 2em;
	font-weight: bold;
}

img {
	margin-top: 10px;
}

button {
	font-size: 25px;
	margin: 50px;
}

.center-middle {
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.counter {
	width: 100%;
}

.panel {
	background: unset;
	color: #fff;
	margin: 50px 0;
}

@media (min-width: 1025px) {
	.panel {
		max-width: 550px;
	}
}

@media (min-width: 1400px) {
	.panel {
		max-width: 650px;
	}
}
</style>