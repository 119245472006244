<template>
	<div class="pagina">
		<div class="container">
			<div class="ferramentas">
				<ul v-if="curStep" id="progressbar">
					<li
						v-for="(pagina, index) in paginas"
						:key="index"
						:class="['pointer', { active: curStep >= index + 1 }]"
						@click="toPage(index + 1)"
					>
						{{ paginas[index].nome }}
					</li>
				</ul>
			</div>

			<br />

			<div class="panel panel-default">
				<div class="panel-body">
					<keep-alive>
						<app-acoes v-if="pagAtual.nome == 'Folha de Ações'" />
						<app-insights
							v-if="pagAtual.nome == 'Folha de Insights'"
						/>
						<app-clareza
							v-if="pagAtual.nome == 'ferramenta de clareza'"
						/>
						<app-m1 v-if="pagAtual.nome == 'definindo o M1'" />
						<app-a-z v-if="pagAtual.nome == 'quiz de A a Z'" />
						<app-falhar
							v-if="pagAtual.nome == 'razões para falhar'"
						/>
						<app-luz-sombra
							v-if="pagAtual.nome == 'Análise luz e sombra'"
						/>
						<app-melhoria
							v-if="pagAtual.nome == 'pontos de melhoria'"
						/>
						<app-regras v-if="pagAtual.nome == 'regras da vida'" />
						<app-afirmacoes
							v-if="pagAtual.nome == 'afirmações positivas'"
						/>
						<app-crencas
							v-if="pagAtual.nome == 'dínamo do relacionamento'"
						/>
						<app-habilidades
							v-if="pagAtual.nome == 'roda de habilidades'"
						/>
					</keep-alive>
				</div>

				<div class="form-group text-center">
					<button
						v-if="curStep != 0"
						class="btn btn-primary"
						:disabled="curStep == 1"
						@click.prevent="toPage(curStep - 1)"
					>
						Anterior
					</button>
					<button
						class="btn btn-primary"
						:disabled="curStep == 12"
						@click.prevent="toPage(curStep + 1)"
					>
						{{ curStep == 0 ? "iniciar" : "Próximo" }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormInsights from "@/components/ferramenta/Insights.vue";
import FormAcoes from "@/components/ferramenta/Acoes.vue";
import FormClareza from "@/components/ferramenta/Clareza.vue";
import FormM1 from "@/components/ferramenta/M1.vue";
import FormAaZ from "@/components/ferramenta/AaZ.vue";
import FormFalhar from "@/components/ferramenta/Falhar.vue";
import FormLuzSombra from "@/components/ferramenta/LuzSombra.vue";
import FormMelhoria from "@/components/ferramenta/Melhorias.vue";
import FormRegras from "@/components/ferramenta/Regras.vue";
import FormAfirmacoes from "@/components/ferramenta/Positivas.vue";
import FormCrencas from "@/components/ferramenta/Crencas.vue";
import FormHabilidades from "@/components/ferramenta/Habilidades.vue";

export default {
	data() {
		return {
			turma: null,
			pagAtual: {},
			paginas: [],
		};
	},
	methods: {
		toPage(pagina) {
			this.$http
				.get(`pagina/${this.$route.params.idEvento}/${pagina}`)
				.then((response) => {
					if (response.body == null) {
						var returnMessage = {
							type: "alert-warning",
							mensagem:
								"Ainda não chegou a hora de fazer essa ferramenta. Aguarde as instruções da Luiza.",
						};
						this.$emit("showMessage", returnMessage);
					} else {
						var pag = {
							nome: response.body.nome,
							numero: response.body.numero,
						};
						this.paginas[pagina - 1] = pag;
						this.pagAtual = pag;
					}
				})
				.then(() => {
					var progBar = document.getElementById("progressbar");

					if (!progBar) return;

					var elements = progBar.querySelectorAll(".active");
					var selecao = elements[elements.length - 1];
					selecao.scrollIntoView();

					progBar.scrollLeft = (this.curStep - 1) * 75;

					//$('html,body').animate({ scrollTop: $(this).offset().top - ( $(window).height() - $(this).outerHeight(true) ) / 2  }, 200);
				});
		},
	},
	computed: {
		curStep() {
			return Number(this.pagAtual.numero);
		},
	},

	created() {
		this.toPage(1);
	},
	components: {
		appAcoes: FormAcoes,
		appInsights: FormInsights,
		appClareza: FormClareza,
		appM1: FormM1,
		appAZ: FormAaZ,
		appFalhar: FormFalhar,
		appLuzSombra: FormLuzSombra,
		appMelhoria: FormMelhoria,
		appRegras: FormRegras,
		appAfirmacoes: FormAfirmacoes,
		appCrencas: FormCrencas,
		appHabilidades: FormHabilidades,
	},
};
</script>

<style scoped>
.btn {
	margin: 5px 10px;
}

/*progressbar*/
#progressbar {
	margin-bottom: 30px;
	overflow: scroll;
	overflow-y: hidden;
	display: flex;
	padding: 0;
	/*CSS counters to number the steps*/
	counter-reset: step;
}

#progressbar li {
	list-style-type: none;
	font-size: 12px;
	margin: 0 10px 10px;
	float: left;
	position: relative;
	letter-spacing: 1px;
	text-align: center;
	min-width: 75px;
}

#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 25px;
	height: 25px;
	line-height: 26px;
	display: block;
	font-size: 12px;
	color: #333;
	background: white;
	border-radius: 25px;
	margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
	content: "";
	width: 120%;
	height: 2px;
	background: white;
	position: absolute;
	left: -70%;
	top: 11px;
	z-index: -1;
}

#progressbar li:first-child:after {
	content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
	background: #d34758;
	color: white;
}

#progressbar li.error:before {
	background: #ff0000;
}

#msform fieldset.step {
	background: white;
	border: 0 none;
	border-radius: 0px;
	box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
	padding: 20px 30px;
	box-sizing: border-box;

	/*stacking fieldsets above each other*/
	position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;
}
</style>