<template>
	<form>
		<fieldset>
			<legend>RODA DE HABILIDADES</legend>

			<div class="form-group">
				<app-slider :value="hab1" @input="setValue('hab1', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab1Nome"
					@change="addToPoll('hab1Nome', hab1Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab2" @input="setValue('hab2', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab2Nome"
					@change="addToPoll('hab2Nome', hab2Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab3" @input="setValue('hab3', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab3Nome"
					@change="addToPoll('hab3Nome', hab3Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab4" @input="setValue('hab4', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab4Nome"
					@change="addToPoll('hab4Nome', hab4Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab5" @input="setValue('hab5', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab5Nome"
					@change="addToPoll('hab5Nome', hab5Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab6" @input="setValue('hab6', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab6Nome"
					@change="addToPoll('hab6Nome', hab6Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab7" @input="setValue('hab7', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab7Nome"
					@change="addToPoll('hab7Nome', hab7Nome)"
				/>
			</div>

			<div class="form-group">
				<app-slider :value="hab8" @input="setValue('hab8', $event)" />
				<input
					class="form-control"
					type="text"
					placeholder="Habilidade"
					v-model="hab8Nome"
					@change="addToPoll('hab8Nome', hab8Nome)"
				/>
			</div>
		</fieldset>

		<div class="graf">
			<app-polar-chart :labels="quesitos" :data="data" />
		</div>

		<fieldset>
			<label>
				QUAL DESSAS HABILIDADES VOCÊ ESCOLHE FOCAR NESTE MOMENTO?
			</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab1Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab1Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab2Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab2Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab3Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab3Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab4Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab4Nome }}</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab5Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab5Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab6Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab6Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab7Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab7Nome }}</span>
						</label>
					</div>

					<div class="checkbox">
						<label>
							<input
								type="radio"
								:value="hab8Nome"
								v-model="hab9"
								@change="addToPoll('hab9', hab9)"
							/>
							<span>{{ hab8Nome }}</span>
						</label>
					</div>
				</div>
			</div>

			<label>
				LISTE 3 AÇÕES QUE VOCÊ PODE TOMAR PARA DESENVOLVER ESSA
				HABILIDADE:
			</label>

			<div class="form-group">
				<label> 1 - </label>

				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="hab10"
					@change="addToPoll('hab10', hab10)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<label> 2 - </label>

				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="hab11"
					@change="addToPoll('hab11', hab11)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<label> 3 - </label>

				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="hab12"
					@change="addToPoll('hab12', hab12)"
				>
				</textarea>
			</div>
		</fieldset>
	</form>
</template>

<script>
import Slider from "@/components/UI/Slider.vue";
import PolarChart from "@/components/UI/PolarChart.vue";

import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";

export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			hab1: 0,
			hab2: 0,
			hab3: 0,
			hab4: 0,
			hab5: 0,
			hab6: 0,
			hab7: 0,
			hab8: 0,
			hab9: {},
			hab10: "",
			hab11: "",
			hab12: "",

			hab1Nome: "",
			hab2Nome: "",
			hab3Nome: "",
			hab4Nome: "",
			hab5Nome: "",
			hab6Nome: "",
			hab7Nome: "",
			hab8Nome: "",
		};
	},

	methods: {
		setValue(prop, value) {
			this[prop] = value;
			this.addToPoll(prop, this[prop]);
		},
	},

	computed: {
		data() {
			var data = new Array();

			data.push(Number(this.hab1));
			data.push(Number(this.hab2));
			data.push(Number(this.hab3));
			data.push(Number(this.hab4));
			data.push(Number(this.hab5));
			data.push(Number(this.hab6));
			data.push(Number(this.hab7));
			data.push(Number(this.hab8));

			return data;
		},
		quesitos() {
			var quesitos = [];
			for (let i = 1; i <= 8; i++) quesitos.push(this[`hab${i}Nome`]);
			return quesitos;
		},
	},

	created() {
		for (var k in this._data)
			if (k.startsWith("hab")) this.carregaRespostas(k, true);
	},

	components: {
		appSlider: Slider,
		appPolarChart: PolarChart,
	},
};
</script>

<style scoped>
.graf {
	max-width: 700px;
	margin: 10px auto;
}

.checkbox {
	margin-top: -4px;
}
</style>