<template>
	<form>
		<fieldset>
			<legend>FOLHA DE AÇÕES</legend>

			<div class="row">
				<div class="col-xs-10 form-group">
					<label>Ações</label>
				</div>
				<div class="col-xs-2 form-group">
					<label>Até quando</label>
				</div>
			</div>

			<div class="row" v-for="(acao, index) in acoes" :key="index">
				<div class="col-md-10 col-xs-8 form-group">
					<input
						type="text"
						class="form-control"
						maxlength="500"
						v-model.lazy="acoes[index]"
						@change="addToPoll('acoes', acoes)"
					/>
				</div>

				<div class="col-md-2 col-xs-4 form-group">
					<input
						type="date"
						class="form-control"
						maxlength="500"
						v-model.lazy="acoesDatas[index]"
						@change="addToPoll('acoesDatas', acoesDatas)"
					/>
				</div>
			</div>

			<div class="form-group text-center">
				<button class="btn btn-primary" @click.prevent="addAcao">
					adicionar Ação
				</button>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			erroList: {},
			acoes: [],
			acoesDatas: [],
		};
	},
	methods: {
		addAcao() {
			this.acoes.push("");
			this.acoesDatas.push("");
		},
	},
	created() {
		for (let i = 0; i < 6; i++) this.addAcao();
		for (var k in this._data) {
			if (k.startsWith("acoes")) this.carregaRespostas(k);
		}
	},
};
</script>

<style scoped>
input[type="date"] {
	min-width: 100px;
}
</style>
