<template>
	<div class="pagina">
		<div class="container">
			<form>
				<fieldset>
					<legend>Cadastro de usuários</legend>

					<div
						:class="['form-group', { 'has-error': erroList.nome }]"
					>
						<label>Nome</label>
						<input
							class="form-control"
							type="text"
							maxlength="60"
							v-model="usuario.nome"
						/>
						<label class="control-label">{{ erroList.nome }}</label>
					</div>

					<div
						:class="['form-group', { 'has-error': erroList.email }]"
					>
						<label>E-mail</label>
						<input
							class="form-control"
							type="text"
							maxlength="255"
							v-model="usuario.email"
						/>
						<label class="control-label">{{
							erroList.email
						}}</label>
					</div>

					<div
						:class="[
							'form-group',
							{ 'has-error': erroList.telefone },
						]"
					>
						<label>Telefone</label>
						<input
							class="form-control"
							type="tel"
							maxlength="20"
							v-model="telefone"
							v-mask="'(##) #####-####'"
						/>
						<label class="control-label">
							{{ erroList.telefone }}
						</label>
					</div>

					<div
						:class="[
							'form-group',
							{ 'has-error': erroList.perfil },
						]"
					>
						<label>Perfil</label><br />

						<label>
							<input
								type="radio"
								value="1"
								v-model="usuario.idPerfil"
							/>
							Administrador </label
						><br />
						<label>
							<input
								type="radio"
								value="2"
								v-model="usuario.idPerfil"
							/>
							Aluna
						</label>
						<label class="control-label">{{
							erroList.perfil
						}}</label>
					</div>

					<p>
						Novos usuários possuem senha 123, que deve ser
						modificada no primeiro acesso
					</p>

					<div class="form-group text-center">
						<button
							:class="['btn btn-primary']"
							:disabled="submiting"
							@click.prevent="submit"
						>
							Cadastrar usuário
						</button>
						<router-link to="/usuario">
							<a class="btn btn-primary">cancelar</a>
						</router-link>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
	data() {
		return {
			submiting: false,
			erroList: [],
			telefone: "",
			usuario: {},
		};
	},
	methods: {
		submit() {
			this.submiting = true;
			this.usuario.telefone = this.telefone.replace(/[^0-9]/g, "");
			this.$http
				.post("usuario", this.usuario)
				.then((response) => {
					return response.json();
				})
				.then(
					(data) => {
						var returnMessage = {
							type: "alert-success",
							mensagem: "Usuário cadastrado com sucesso!",
						};
						this.$emit("showMessage", returnMessage);
						this.$router.push("/usuario/" + data);
						this.submiting = false;
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "há campos incorreto no formulário!",
							};
							this.$emit("showMessage", returnMessage);
							this.erroList = error.body;
							this.submiting = false;
						}
					}
				);
		},
	},
	directives: { mask },
};
</script>