<template>
	<div id="aplicacao" @drop.prevent.stop @dragover.prevent.stop>
		<app-header />
		<app-message
			:message="returnMessage"
			v-if="returnMessage"
			@closeMessage="returnMessage = $event"
		></app-message>
		<router-view @showMessage="returnMessage = $event" />
		<app-footer />
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
import footer from "@/components/Footer.vue";

export default {
	data() {
		return { returnMessage: null };
	},
	components: {
		appHeader: Header,
		appFooter: footer,
	},
	created() {
		let redirect = ["/login", "/resetSenha"].includes(this.$route.path);
		if (!(this.isLogedIn || redirect)) this.$router.push("/login");
	},
	watch: {},
};
</script>

<style>
a {
	color: #d34758;
}

a:hover {
	color: #ca071f;
}

form {
	margin-bottom: 15px;
}

.ta-G {
	height: calc(1em * 1.6 * 5) !important;
}

.ta-P {
	height: calc(1em * 1.6 * 2) !important;
}

header {
	background-color: #e8ebf0;
}
.pointer {
	cursor: pointer;
}
.newLine {
	white-space: pre-wrap;
}

.btn-primary {
	color: #fff;
	background-color: #df7280;
	border-color: #ca071f;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
	color: #fff;
	background-color: #d34758;
	border-color: #ca071f;
}

p {
	text-indent: 50px;
	text-align: justify;
	letter-spacing: 1px;
	margin-bottom: 13px;
}
.texto {
	padding: 10px;
	margin: 15px 0;
}

.table-hover > tbody > tr:hover {
	background-color: #fff1f3;
}

.pagina {
	padding-top: 50px;
}

.pagina-luisa {
	background-image: url("~@/assets/bg.png");
	background-size: cover;
	background-attachment: fixed;
	background-position: right top -10%;
	padding-top: 50px;
	min-height: 550px;
}

@media (min-width: 1025px) {
	.pagina-luisa {
		background-image: url("~@/assets/bg-luisa.png");
	}
}
</style>
