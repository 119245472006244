import Login from "@/components/login/Login.vue";
import Reset from "@/components/login/TrocaSenha.vue";

import Apresentacao from "@/components/Apresentacao.vue";
import Suporte from "@/components/Suporte.vue";
import Pesquisa from "@/components/Pesquisa.vue";
import Formulario from "@/components/ferramenta/Formulario.vue";

import Usuarios from "@/components/usuario/Lista.vue";
import SelecaoUsuario from "@/components/usuario/Seleciona.vue";
import FormularioUsuario from "@/components/usuario/Formulario.vue";

import FormularioMatricula from "@/components/matricula/Formulario.vue";

import ListaEvento from "@/components/evento/Eventos.vue";
import SelecaoTurma from "@/components/evento/Evento.vue";
import FormularioEvento from "@/components/evento/Formulario.vue";

export const routes = [
	{ path: "/apresentacao", component: Apresentacao, name: "Apresentacao" },
	{ path: "/duvida", component: Suporte, name: "Suporte" },
	{ path: "/pesquisa", component: Pesquisa, name: "Pesquisa" },

	{ path: "/ferramenta/:idEvento", component: Formulario, name: "Formulario" },

	{ path: "/login", component: Login, name: "login" },
	{ path: "/resetSenha", component: Reset, name: "reset" },

	{ path: "/usuario", component: Usuarios, name: "usuarios" },
	{ path: "/usuario/novo", component: FormularioUsuario, name: "formularioUsuario" },
	{ path: "/usuario/:idUsuario", component: SelecaoUsuario, name: "usuario" },

	{ path: "/matricula/novo/:idEvento", component: FormularioMatricula, name: "formularioMatricula" },

	{ path: "/evento", component: ListaEvento, name: "eventos" },
	{ path: "/evento/novo", component: FormularioEvento, name: "formularioEvento" },
	{ path: "/evento/:idEvento", component: SelecaoTurma, name: "evento" },

	{ path: "*", redirect: { name: "Apresentacao" } }
];
