<template>
	<form>
		<fieldset>
			<div
				v-if="isCriacao"
				:class="['form-group', { 'has-error': erroList.idUsuario }]"
			>
				<label>Email</label>
				<input
					class="form-control"
					type="text"
					maxlength="60"
					v-model="email"
				/>
				<label class="control-label">{{ erroList.idUsuario }}</label>
			</div>

			<div :class="['form-group', { 'has-error': erroList.senha }]">
				<label for="senha" @click="showSenha = !showSenha">
					Senha
					<span
						:class="[
							'glyphicon',
							{ 'glyphicon-eye-open': !showSenha },
							{ 'glyphicon-eye-close': showSenha },
						]"
					></span>
				</label>
				<input
					id="senha"
					class="form-control"
					:type="passFieldType"
					maxlength="60"
					v-model="senha"
				/>
				<label class="control-label">{{ erroList.senha }}</label>
			</div>
			<div class="form-group text-center">
				<button
					:class="['btn btn-primary']"
					:disabled="submiting"
					@click.prevent="submit"
				>
					Alterar
				</button>
			</div>
		</fieldset>
	</form>
</template>
<script>
export default {
	props: ["isCriacao"],
	data() {
		return {
			submiting: false,
			showSenha: false,
			email: "",
			senha: "",
			erroList: [],
		};
	},
	methods: {
		submit() {
			this.submiting = true;
			this.erroList = [];

			if (this.isCriacao) this.createSenha();
			else this.resetSenha();
		},

		createSenha() {
			let senha = {
				email: this.email,
				senha: this.senha,
			};

			this.$http
				.post("senha", senha)
				.then(
					() => {
						var returnMessage = {
							type: "alert-success",
							mensagem:
								"Senha Cadastrada com sucesso. Faça login para acessar o sistema",
						};
						this.$parent.$emit("showMessage", returnMessage);
						this.senha = "";
						this.$router.push("/login");
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "Erro no cadastro de senha",
							};
							this.$parent.$emit("showMessage", returnMessage);
							this.erroList = error.body;
						}
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},

		resetSenha() {
			let senha = {
				idUsuario: this.$route.params.idUsuario,
				senha: this.senha,
			};

			this.$http
				.put("senha", senha)
				.then(
					() => {
						var returnMessage = {
							type: "alert-success",
							mensagem: "Senha alterada com sucesso!",
						};
						this.$parent.$emit("showMessage", returnMessage);
						this.senha = "";
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "Erro no cadastro de senha 123",
							};
							this.$parent.$emit("showMessage", returnMessage);
							this.erroList = error.body;
						}
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},
	},
	computed: {
		passFieldType() {
			return this.showSenha ? "text" : "password";
		},
	},
};
</script>