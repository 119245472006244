<template>
	<form>
		<fieldset>
			<legend>FOLHA DE INSIGHTS</legend>

			<div
				class="form-group"
				v-for="(insight, index) in insights"
				:key="index"
			>
				<input
					type="text"
					class="form-control"
					maxlength="500"
					v-model.lazy="insights[index]"
					@change="addToPoll('insights', insights)"
				/>
			</div>

			<div class="form-group text-center">
				<button class="btn btn-primary" @click.prevent="addInsight">
					adicionar Insight
				</button>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			insights: [],
		};
	},
	methods: {
		addInsight() {
			this.insights.push("");
		},
	},
	created() {
		for (let i = 0; i < 6; i++) this.addInsight();
		for (var k in this._data) {
			if (k.startsWith("insights")) this.carregaRespostas(k);
		}
	},
};
</script>

<style>
</style>
