<template>
	<form>
		<fieldset>
			<legend>RAZÕES PARA FALHAR</legend>

			<div class="panel panel-default quadrante">
				<div class="panel-heading">ASPECTOS MENTAIS</div>
				<div class="panel-body">
					<div class="row">
						<div class="col-xs-12 col-sm-6 form-group">
							<label>Medos</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha1"
								@change="addToPoll('falha1', falha1)"
							>
							</textarea>
						</div>

						<div class="col-xs-12 col-sm-6 form-group">
							<label>Crenças</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha2"
								@change="addToPoll('falha2', falha2)"
							>
							</textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falhas de autoconceito</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha3"
								@change="addToPoll('falha3', falha3)"
							>
							</textarea>
						</div>

						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falta de conhecimento</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha4"
								@change="addToPoll('falha4', falha4)"
							>
							</textarea>
						</div>
					</div>
				</div>
			</div>

			<div class="panel panel-lg panel-default">
				<div class="panel-heading">ASPECTOS FÍSICOS</div>
				<div class="panel-body">
					<div class="row">
						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falta de tempo</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha5"
								@change="addToPoll('falha5', falha5)"
							>
							</textarea>
						</div>

						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falta de energia</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha6"
								@change="addToPoll('falha6', falha6)"
							>
							</textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falhas de autoconceito</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha7"
								@change="addToPoll('falha7', falha7)"
							>
							</textarea>
						</div>

						<div class="col-xs-12 col-sm-6 form-group">
							<label>Falta de conhecimento</label>
							<textarea
								class="form-control ta-G"
								maxlength="1000"
								v-model.lazy="falha8"
								@change="addToPoll('falha8', falha8)"
							>
							</textarea>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			falha1: "",
			falha2: "",
			falha3: "",
			falha4: "",
			falha5: "",
			falha6: "",
			falha7: "",
			falha8: "",
		};
	},
	methods: {},
	watch: {},
	created() {
		for (var k in this._data) {
			if (k.startsWith("falha")) this.carregaRespostas(k, true);
		}
	},
};
</script>

<style scoped>
</style>