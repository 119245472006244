<template>
	<div class="pagina-luisa">
		<div class="container">
			<div class="panel mensagem">
				<div class="panel-body text-center">
					<div>
						<div class="panel panel-default">
							<div class="panel-body">
								<span
									>Baixe este manual e fique por dentro de
									tudo
								</span>
								<a
									class="btn btn-primary"
									href="/manual.pdf"
									target="_blank"
								>
									Manual da imersão
								</a>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-body text-center">
								<span>Dificuldade técnica com o Zoom?</span>
								<a
									class="btn btn-primary"
									href="https://youtu.be/9ZtMoiD5E-0"
									target="_blank"
								>
									Tutorial do ZOOM
								</a>
							</div>
						</div>

						<div class="panel panel-default">
							<div class="panel-body">
								<span>Fale com o meu time de suporte</span>
								<a
									class="btn btn-primary"
									:href="zapURL"
									target="_blank"
								>
									Whatsapp do suporte
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			numero: 556199935906,
			texto: "Tenho uma dúvida sobre a Imersão Online",
		};
	},
	methods: {},
	computed: {
		zapURL() {
			var isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
				isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
				isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
				isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
				isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

			var tipo =
				isMobile || isTablet || isAndroid || isiPhone || isiPad
					? "api"
					: "web";

			return `https://${tipo}.whatsapp.com/send?phone=${
				this.numero
			}&text=${encodeURI(this.texto)}`;
		},
	},
	created() {},
};
</script>

<style scoped>
h1 {
	font-size: 2em;
	font-weight: bold;
}

a {
	display: block;
	margin: 20px 0 0 0;
	font-size: 1.8rem;
}

.panel {
	font-size: 2rem;
	background: unset;
	color: #fff;
}

.center-middle {
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mensagem {
	color: #fff;
	border: unset;
}

@media (min-width: 1025px) {
	.panel {
		max-width: 550px;
	}
}

@media (min-width: 1400px) {
	.panel {
		max-width: 650px;
	}
}
</style>