<template>
	<div class="pagina-luisa">
		<div class="container">
			<div class="panel-group">
				<div class="panel panel-lg panel-default">
					<div class="panel-heading">Cadastro de nova senha</div>
					<div class="panel-body">
						<p>
							Verificamos que você é uma aluna nova. Cadestre sua
							nova senha para acesso.
						</p>
						<app-senha
							:isCriacao="true"
							@showMessage="returnMessage = $event"
						></app-senha>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FormSenha from "@/components/login/FormSenha.vue";

export default {
	components: {
		appSenha: FormSenha,
	},
};
</script>
<style scoped>
.pagina-luisa {
	padding-bottom: 50px;
}

@media (min-width: 1025px) {
	.panel {
		max-width: 550px;
	}
}

@media (min-width: 1400px) {
	.panel {
		max-width: 650px;
	}
}
</style>