<template>
	<canvas id="myPolarChart"></canvas>
</template>
<script>
import Chart from "chart.js/auto";

export default {
	props: ["labels", "data"],
	data() {
		return {
			grafico: null,
			dados: {
				labels: this.labels,
				datasets: [
					{
						data: this.data,
						backgroundColor: [
							"rgba(255,0,0,0.3)",
							"rgba(0,255,0,0.3)",
							"rgba(0,0,255,0.3)",
							"rgba(255, 255, 0,0.3)",
							"rgba(0, 255, 255,0.3)",
							"rgba(255, 0, 255,0.3)",
							"rgba(0,0,0,0.3)",
							"rgba(255, 115, 0,0.3)",
						],
					},
				],
			},
			options: {
				plugins: {
					legend: {
						position: "bottom",
					},
				},
				scales: {
					r: {
						max: 10,
						min: 0,
						ticks: {
							stepSize: 1,
						},
					},
				},
			},
		};
	},
	methods: {
		startGraph() {
			var ctx = document.getElementById("myPolarChart");
			this.grafico = new Chart(ctx, {
				type: "polarArea",
				data: this.dados,
				options: this.options,
			});
		},
	},
	watch: {
		data: function () {
			this.grafico.data.datasets[0].data = this.data;

			//this.grafico.canvas.parentNode.style.height = '128px';
			//this.grafico.canvas.parentNode.style.width = '700px';

			this.grafico.update();
		},
		labels() {
			this.grafico.data.labels = this.labels;
			this.grafico.update();
		},
	},
	mounted() {
		this.startGraph();
		this.grafico.resize(500, 500);
	},
};
</script>

<style>
</style>
