<template>
	<nav class="navbar navbar-default">
		<div class="navbar-header">
			<img class="logo" src="@/assets/logo.png" />
		</div>
		<div class="">
			<ul class="nav navbar-nav">
				<router-link
					v-if="isLogedIn"
					tag="li"
					active-class="active"
					to="/apresentacao"
					exact
				>
					<a>Início</a>
				</router-link>
				<router-link
					v-if="isLogedIn"
					tag="li"
					active-class="active"
					to="/duvida"
					exact
				>
					<a>Dúvidas</a>
				</router-link>
				<router-link
					v-if="isLogedIn"
					tag="li"
					active-class="active"
					to="/pesquisa"
					exact
				>
					<a>Pesquisa</a>
				</router-link>
				<router-link
					v-if="isAdm"
					tag="li"
					active-class="active"
					to="/usuario"
					exact
				>
					<a>Usuários</a>
				</router-link>
				<router-link
					v-if="isAdm"
					tag="li"
					active-class="active"
					to="/evento"
					exact
				>
					<a>Eventos</a>
				</router-link>
			</ul>
			<ul class="nav navbar-nav navbar-right">
				<router-link
					v-if="isLogedIn"
					tag="li"
					active-class="active"
					:to="'/usuario/' + logedUser"
					exact
				>
					<a>Perfil</a>
				</router-link>
				<li v-if="isLogedIn" class="pointer" @click="logout">
					<a>Logout</a>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>
export default {
	data() {
		return {
			numero: 5561996627677,
			texto: "texto padrão enviado no zap",
		};
	},
};
</script>
<style scoped>
img.logo {
	width: 40px;
	padding: 5px;
	margin: 5px;
}
.navbar {
	border-radius: 0;
	border: 0;
	margin-bottom: 0;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #000;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: white;
	background-color: #9e1928;
}
.navbar-default {
	background-color: #d34758;
}
.navbar-nav {
	margin: 0 5px;
}
.nav > li > a:hover,
.nav > li > a:focus {
	text-decoration: none;
}
.navbar-default .navbar-nav > li > a {
	color: white;
}

.navbar-right {
	margin-right: 15px;
}
</style>
