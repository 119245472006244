<template>
	<div class="container">
		<div :id="id" :class="['alert', message.type]">
			{{ message.mensagem }}
			<a class="close pointer" @click="close">&times;</a>
		</div>
	</div>
</template>

<script>
export default {
	props: ["message"],
	data() {
		return { id: null };
	},
	methods: {
		close() {
			this.$emit("closeMessage", false);
		},
		autoClose() {
			setTimeout(this.close, 7500);
		},
		autoFocus() {
			document.getElementById(this.id).scrollIntoView();
		},
	},
	created() {
		this.id = `userMessage${this._uid}`;
	},
	mounted() {
		this.autoClose();
		this.autoFocus();
	},
};
</script>

<style>
.alert {
	position: fixed;
	z-index: 5;
	width: 60vw;
}
</style>
