<template>
	<form>
		<fieldset>
			<legend>AFIRMAÇÕES POSITIVAS</legend>

			<label
				>QUAIS SÃO AS 10 AFIRMAÇÕES POSITIVAS QUE VOCÊ VAI INCLUIR NA
				SUA ROTINA DIÁRIA?</label
			>

			<div
				class="form-group"
				v-for="(positiva, index) in positivas"
				:key="index"
			>
				<input
					type="text"
					class="form-control"
					maxlength="500"
					v-model.lazy="positivas[index]"
					@change="addToPoll('positivas', positivas)"
				/>
			</div>

			<div class="form-group text-center">
				<button class="btn btn-primary" @click.prevent="addPositiva">
					adicionar Afirmação
				</button>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			positivas: [],
		};
	},
	methods: {
		addPositiva() {
			this.positivas.push("");
		},
	},
	created() {
		for (let i = 0; i < 10; i++) this.addPositiva();
		for (var k in this._data) {
			if (k.startsWith("positiva")) this.carregaRespostas(k);
		}
	},
};
</script>

<style>
</style>
