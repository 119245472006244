<template>
	<table class="table table-hover">
		<thead>
			<tr>
				<th>Nome</th>
				<th>email</th>
				<th>telefone</th>
				<th>Tipo</th>
			</tr>
		</thead>
		<tbody>
			<tr
				class="pointer"
				v-for="(usuario, index) in usuarioList"
				:key="index"
				@click="$emit('selecao', usuario.id)"
			>
				<td>{{ usuario.nome }}</td>
				<td>{{ usuario.email }}</td>
				<td>{{ usuario.telefone | telefone }}</td>
				<td>{{ usuario.perfil.perfil }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	props: ["usuarioList"],
	methods: {},
};
</script>

<style>
</style>