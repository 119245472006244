<template>
	<div class="pagina">
		<div class="container">
			<div class="panel-group" v-if="usuario">
				<div class="panel panel-lg panel-default" v-if="usuario">
					<div class="panel-heading">
						<div class="row">
							<div class="col-md-9">
								<h3>Usuário</h3>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<dl>
							<dt>Nome:</dt>
							<dd v-if="!editName">
								<a
									@click="toggleEdit('editName')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ usuario.nome }}
							</dd>

							<div
								v-if="editName"
								:class="[
									'input-group',
									{ 'has-error': erroList.nome },
								]"
							>
								<span
									@click="mudaNome"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editName')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>
								<input
									type="text"
									class="form-control"
									v-model="nome"
								/>
								<label class="control-label">{{
									erroList.nome
								}}</label>
							</div>

							<dt>Email:</dt>
							<dd v-if="!editEmail">
								<a
									@click="toggleEdit('editEmail')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ usuario.email }}
							</dd>

							<div
								v-if="editEmail"
								:class="[
									'input-group',
									{ 'has-error': erroList.email },
								]"
							>
								<span
									@click="mudaEmail"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editEmail')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>
								<input
									type="text"
									class="form-control"
									v-model="email"
								/>
								<label class="control-label">{{
									erroList.email
								}}</label>
							</div>

							<dt>Telefone:</dt>
							<dd v-if="!editTelefone">
								<a
									@click="toggleEdit('editTelefone')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ usuario.telefone }}
							</dd>

							<div
								v-if="editTelefone"
								:class="[
									'input-group',
									{ 'has-error': erroList.telefone },
								]"
							>
								<span
									@click="mudaTelefone"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editTelefone')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>
								<input
									type="tel"
									class="form-control"
									v-mask="'(##) #####-####'"
									maxlength="20"
									v-model="telefone"
								/>
								<label class="control-label">
									{{ erroList.telefone }}
								</label>
							</div>

							<dt>Perfil de Acesso:</dt>
							<dd>
								<a
									v-if="isAdm && !editPerfil"
									@click="toggleEdit('editPerfil')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{
									usuario.perfil.id == 1
										? "Admisnitrador"
										: "Usuário"
								}}
							</dd>

							<div
								v-if="editPerfil"
								:class="[
									'input-group',
									{ 'has-error': erroList.perfil },
								]"
							>
								<span
									@click="mudaPerfil"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editPerfil')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>

								<label>
									<input
										type="radio"
										value="1"
										v-model="idPerfil"
									/>
									Administrador
								</label>
								<label>
									<input
										type="radio"
										value="2"
										v-model="idPerfil"
									/>
									colaborador
								</label>
								<label class="control-label">{{
									erroList.perfil
								}}</label>

								<label class="control-label">
									{{ erroList.perfil }}
								</label>
							</div>
						</dl>
					</div>
				</div>

				<div class="panel panel-lg panel-default">
					<div class="panel-heading">Troca de senha</div>
					<div class="panel-body">
						<app-senha
							@showMessage="returnMessage = $event"
						></app-senha>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormSenha from "@/components/login/FormSenha.vue";
import { mask } from "vue-the-mask";

export default {
	data() {
		return {
			usuario: null,
			filaUsuarioList: [],
			erroList: [],
			nome: "",
			telefone: "",
			email: "",
			idPerfil: "",
			submiting: false,
			editName: false,
			editEmail: false,
			editTelefone: false,
			editPerfil: false,
		};
	},
	methods: {
		remFilaUsuario(indexFila) {
			this.filaUsuarioList.splice(indexFila, 1);
			var returnMessage = {
				type: "alert-success",
				mensagem: "Acesso a fila removido com sucesso!",
			};
			this.$emit("showMessage", returnMessage);
		},
		addFilaUsuario(fila) {
			this.filaUsuarioList.push(fila);
			var returnMessage = {
				type: "alert-success",
				mensagem: "Acesso a fila adicionado com sucesso!",
			};
			this.$emit("showMessage", returnMessage);
		},

		toggleEdit(field) {
			this[field] = !this[field];
			if (!this.editTelefone) this.telefone = this.usuario.telefone;
			if (!this.editEmail) this.email = this.usuario.email;
			if (!this.editName) this.nome = this.usuario.nome;
			if (!this.editPerfil) this.idPerfil = this.usuario.perfil.id;
		},

		mudaNome() {
			this.submiting = true;
			let json = {
				idUsuario: this.$route.params.idUsuario,
				nome: this.nome,
			};
			this.ModificarUsuario(json, "editName");
		},

		mudaTelefone() {
			this.submiting = true;
			let json = {
				idUsuario: this.$route.params.idUsuario,
				telefone: this.telefone.replace(/[^0-9]/g, ""),
			};
			this.ModificarUsuario(json, "editTelefone");
		},

		mudaEmail() {
			this.submiting = true;
			let json = {
				idUsuario: this.$route.params.idUsuario,
				email: this.email,
			};
			this.ModificarUsuario(json, "editEmail");
		},

		mudaPerfil() {
			this.submiting = true;
			let json = {
				idUsuario: this.$route.params.idUsuario,
				idPerfil: this.idPerfil,
			};
			this.ModificarUsuario(json, "editPerfil");
		},

		ModificarUsuario(json, field) {
			this.$http
				.put("usuario", json)
				.then(
					(response) => {
						this.usuario = response.body;
						this.erroList = [];
						if (field) this.toggleEdit(field);
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "há campos incorreto no formulário!",
							};
							this.$emit("showMessage", returnMessage);
							this.erroList = error.body;
						}
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},

		getUsuario() {
			this.$http
				.get(`usuario/${this.$route.params.idUsuario}`)
				.then(
					(response) => {
						return response.json();
					},
					(erro) => {
						var returnMessage = {
							type: "alert-danger",
							mensagem: erro.body,
						};
						this.$emit("showMessage", returnMessage);
						this.$router.push(`/usuario/${this.logedUser}`);
					}
				)
				.then((data) => {
					this.usuario = data;

					this.nome = this.usuario.nome;
					this.telefone = this.usuario.telefone;
					this.email = this.usuario.email;
					this.idPerfil = this.usuario.perfil.id;
				});
		},
	},
	created() {
		this.getUsuario();
	},
	watch: {
		$route: function (to, from) {
			if (to.params.idUsuario !== from.params.idUsuario)
				this.getUsuario();
		},
	},
	components: {
		appSenha: FormSenha,
	},
	directives: { mask },
};
</script>
