<template>
	<form class="form-horizontal">
		<fieldset>
			<legend>QUIZ DE A A Z</legend>

			<label>COMPLETE COM AS SUAS CARACTERÍSTICAS POSITIVAS:</label><br />

			<div class="form-group" v-for="(item, index) in AaZ" :key="index">
				<label class="control-label col-xs-1">
					{{ (index + 65) | nToChar }}
				</label>
				<div class="col-xs-11">
					<input
						type="text"
						class="form-control"
						maxlength="500"
						v-model.lazy="AaZ[index]"
						@change="addToPoll('AaZ', AaZ)"
					/>
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			AaZ: [],
		};
	},
	methods: {
		addItem() {
			this.AaZ.push("");
		},
	},
	filters: {
		nToChar(num) {
			return String.fromCharCode(num);
		},
	},
	created() {
		for (let i = 0; i < 26; i++) this.addItem();
		for (var k in this._data) {
			if (k.startsWith("AaZ")) this.carregaRespostas(k);
		}
	},
};
</script>

<style>
</style>
