<template>
	<form>
		<fieldset>
			<legend>ANÁLISE LUZ E SOMBRA</legend>

			<div class="panel-group">
				<div class="panel panel-lg panel-default">
					<div class="panel-heading">LADO DE LUZ</div>
					<div class="panel-body">
						<div class="form-group">
							<label>Luz Interior</label>

							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="competências, talentos, habilidades"
								v-model.lazy="luzSombra1"
								@change="addToPoll('luzSombra1', luzSombra1)"
							></textarea>
						</div>

						<div class="form-group">
							<label>Luz Exterior</label>
							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="aparência física"
								v-model.lazy="luzSombra2"
								@change="addToPoll('luzSombra2', luzSombra2)"
							></textarea>
						</div>

						<div class="form-group">
							<label>Luz ao Redor</label>
							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="vida"
								v-model.lazy="luzSombra3"
								@change="addToPoll('luzSombra3', luzSombra3)"
							></textarea>
						</div>
					</div>
				</div>

				<div class="panel panel-lg panel-default">
					<div class="panel-heading">LADO DE SOMBRA</div>
					<div class="panel-body">
						<div class="form-group">
							<label>Sombra Interior</label>
							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="competências, talentos, habilidades"
								v-model.lazy="luzSombra4"
								@change="addToPoll('luzSombra4', luzSombra4)"
							></textarea>
						</div>

						<div class="form-group">
							<label>Sombra Exterior</label>
							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="aparência física"
								v-model.lazy="luzSombra5"
								@change="addToPoll('luzSombra5', luzSombra5)"
							></textarea>
						</div>

						<div class="form-group">
							<label>Sombra ao Redor</label>
							<textarea
								class="form-control ta-G"
								maxlength="500"
								placeholder="vida"
								v-model.lazy="luzSombra6"
								@change="addToPoll('luzSombra6', luzSombra6)"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
		<hr />
		<fieldset>
			<div class="form-group">
				<label>
					O QUE MAIS TE CHAMOU ATENÇÃO AO FAZER ESSA ANÁLISE DE LUZ E
					SOMBRA?
				</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="luzSombra7"
					@change="addToPoll('luzSombra7', luzSombra7)"
				></textarea>
			</div>

			<div class="form-group">
				<label>
					COMO VOCÊ PODE APROVEITAR DA MELHOR FORMA POSSÍVEL O SEU
					LADO DE LUZ?
				</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="luzSombra8"
					@change="addToPoll('luzSombra8', luzSombra8)"
				></textarea>
			</div>

			<div class="form-group">
				<label>COMO VOCÊ PODE MINIMIZAR O SEU LADO SOMBRA?</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model.lazy="luzSombra9"
					@change="addToPoll('luzSombra9', luzSombra9)"
				></textarea>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			luzSombra1: "",
			luzSombra2: "",
			luzSombra3: "",
			luzSombra4: "",
			luzSombra5: "",
			luzSombra6: "",
			luzSombra7: "",
			luzSombra8: "",
			luzSombra9: "",
		};
	},
	methods: {},
	created() {
		for (var k in this._data) {
			if (k.startsWith("luzSombra")) this.carregaRespostas(k);
		}
	},
};
</script>

<style>
</style>
