<template>
	<footer class="footer blue">
		<div class="container">
			<p>Plataforma Imersão Luiza Vono</p>
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {};
	},
};
</script>
<style scoped>
footer {
	background-color: #d34758;
	color: white;
	padding-top: 7px;
}
</style>