<template>
	<form>
		<fieldset>
			<legend>REGRAS DA VIDA</legend>

			<div class="row">
				<div class="col-xs-10 form-group">
					<label>Regra</label>
				</div>
				<div class="col-xs-2 form-group">
					<label>Nota</label>
				</div>
			</div>

			<div class="row" v-for="(regra, index) in regras1" :key="index">
				<div class="col-xs-10 form-group">
					<textarea
						class="form-control ta-p"
						maxlength="500"
						v-model.lazy="regras1[index]"
						@change="addToPoll('regras1', regras1)"
					></textarea>
				</div>

				<div class="col-xs-2 form-group">
					<input
						type="number"
						class="form-control"
						maxlength="500"
						v-model.lazy="regras1Nota[index]"
						@change="addToPoll('regras1Nota', regras1Nota)"
					/>
				</div>
			</div>

			<div class="panel panel-lg panel-default">
				<div class="panel-heading">AÇÕES E DECISÕES:</div>
				<div class="panel-body">
					<div class="form-group">
						<label>1</label>
						<textarea
							class="form-control"
							maxlength="500"
							v-model.lazy="regras2"
							@change="addToPoll('regras2', regras2)"
						></textarea>
					</div>

					<div class="form-group">
						<label>2</label>
						<textarea
							class="form-control"
							maxlength="500"
							v-model.lazy="regras3"
							@change="addToPoll('regras3', regras3)"
						></textarea>
					</div>

					<div class="form-group">
						<label>3</label>
						<textarea
							class="form-control"
							maxlength="500"
							v-model.lazy="regras4"
							@change="addToPoll('regras4', regras4)"
						></textarea>
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			regras1: [],
			regras1Nota: [],
			regras2: [],
			regras3: [],
			regras4: [],
		};
	},
	methods: {
		addRegra() {
			this.regras1.push("");
			this.regras1Nota.push("");
		},
	},
	created() {
		for (let i = 0; i < 5; i++) this.addRegra();
		for (var k in this._data) {
			if (k.startsWith("regras1")) this.carregaRespostas(k);
			if (k.startsWith("regras2")) this.carregaRespostas(k, true);
			if (k.startsWith("regras3")) this.carregaRespostas(k, true);
			if (k.startsWith("regras4")) this.carregaRespostas(k, true);
		}
	},
};
</script>

<style>
input[type="number"] {
	appearance: textfield;
}
</style>
