<template>
	<form>
		<fieldset>
			<legend>PONTOS DE MELHORIA</legend>

			<label>QUAIS SÃO OS SEUS PONTOS DE MELHORIA?</label>

			<div
				class="form-group"
				v-for="(melhoria, index) in melhorias"
				:key="index"
			>
				<input
					type="text"
					class="form-control"
					maxlength="500"
					v-model.lazy="melhorias[index]"
					@change="addToPoll('melhorias', melhorias)"
				/>
			</div>

			<div class="form-group text-center">
				<button class="btn btn-primary" @click.prevent="addMelhoria">
					adicionar Melhoria
				</button>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			melhorias: [],
		};
	},
	methods: {
		addMelhoria() {
			this.melhorias.push("");
		},
	},
	created() {
		for (let i = 0; i < 6; i++) this.addMelhoria();
		for (var k in this._data) {
			if (k.startsWith("melhorias")) this.carregaRespostas(k);
		}
	},
};
</script>

<style>
</style>
