<template>
	<div class="pagina-luisa">
		<div class="container">
			<div class="panel panel-default">
				<div class="panel-body">
					<form>
						<fieldset>
							<legend>Login</legend>

							<div
								:class="[
									'form-group',
									{ 'has-error': erroList.usuario },
								]"
							>
								<label>Email</label>
								<input
									class="form-control"
									type="text"
									required
									v-model="usuario.email"
								/>
								<label class="control-label">{{
									erroList.usuario
								}}</label>
							</div>

							<div
								:class="[
									'form-group',
									{ 'has-error': erroList.usuario },
								]"
							>
								<label @click="showSenha = !showSenha">
									Senha
									<span
										:class="[
											'glyphicon',
											{
												'glyphicon-eye-open':
													!showSenha,
											},
											{
												'glyphicon-eye-close':
													showSenha,
											},
										]"
									></span>
								</label>
								<input
									class="form-control"
									:type="passFieldType"
									required
									v-model="usuario.senha"
								/>
								<label class="control-label">{{
									erroList.usuario
								}}</label>
							</div>

							<div class="form-group">
								<button
									:class="[
										'btn',
										'btn-primary',
										{ disabled: submiting },
									]"
									@click.prevent="autentica"
								>
									Entrar
								</button>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showSenha: false,
			submiting: false,
			erroList: [],
			usuario: { email: null, senha: null },
		};
	},
	methods: {
		autentica() {
			this.submiting = true;
			this.$http.post("auth", this.usuario).then(
				(response) => {
					if (response.status === 205)
						this.$router.push("/resetSenha");
					else this.login(response.bodyText);
				},
				(error) => {
					this.erroList = error.body;
					this.submiting = false;
				}
			);
		},
	},
	computed: {
		passFieldType() {
			return this.showSenha ? "text" : "password";
		},
	},
	created() {
		if(this.isLogedIn)
			this.$router.push("/apresentacao");
	},
};
</script>

<style scoped>
legend {
	color: #170a14;
}

button {
	width: 100%;
}

.pagina-luisa {
	padding-bottom: 50px;
}

@media (min-width: 1025px) {
	.panel {
		max-width: 550px;
	}
}

@media (min-width: 1400px) {
	.panel {
		max-width: 650px;
	}
}
</style>
