<template>
	<div class="pagina">
		<div class="container">
			<div class="panel-group">
				<div class="panel panel-lg panel-default" v-if="turma">
					<div class="panel-heading">Evento</div>
					<div class="panel-body">
						<dl>
							<dt>Nome:</dt>
							<dd v-if="!editName">
								<a
									@click="toggleEdit('editName')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ turma.nome }}
							</dd>

							<div
								v-if="editName"
								:class="[
									'input-group',
									{ 'has-error': erroList.nome },
								]"
							>
								<span
									@click="mudaNome"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editName')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>
								<input
									type="text"
									class="form-control"
									v-model="nome"
								/>
								<label class="control-label">
									{{ erroList.nome }}
								</label>
							</div>

							<dt>Observações:</dt>
							<dd v-if="!editObservacao">
								<a
									@click="toggleEdit('editObservacao')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ turma.observacao }}
							</dd>

							<div
								v-if="editObservacao"
								:class="[
									'input-group',
									{ 'has-error': erroList.observacao },
								]"
							>
								<span
									@click="mudaObservacao"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editObservacao')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>
								<input
									type="text"
									class="form-control"
									v-model="observacao"
								/>
								<label class="control-label">{{
									erroList.observacao
								}}</label>
							</div>

							<dt>Início:</dt>
							<dd v-if="!editInicio">
								<a
									@click="toggleEdit('editInicio')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ turma.inicio | dataHora }}
							</dd>

							<div
								v-if="editInicio"
								:class="[
									'input-group',
									{ 'has-error': erroList.inicio },
								]"
							>
								<span
									@click="mudaInicio"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editInicio')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>

								<div class="col-md-4">
									<label>Data</label>
									<input
										class="form-control"
										type="date"
										v-model="dataInicio"
									/>
								</div>
								<div class="col-md-4">
									<label>Horário</label>
									<input
										class="form-control"
										type="time"
										maxlength="255"
										v-model="horaInicio"
									/>
								</div>

								<label class="control-label">{{
									erroList.inicio
								}}</label>
							</div>

							<dt>Situacao:</dt>
							<dd v-if="!editSituacao">
								<a
									@click="toggleEdit('editSituacao')"
									class="glyphicon glyphicon-pencil pointer"
									title="Editar"
								></a>
								{{ turma.situacao.situacao }}
							</dd>

							<div
								v-if="editSituacao"
								:class="[
									'input-group',
									{ 'has-error': erroList.situacao },
								]"
							>
								<span
									@click="mudaSituacao"
									class="
										input-group-addon
										glyphicon glyphicon-ok
										pointer
									"
									title="Salvar"
								></span>
								<span
									@click="toggleEdit('editSituacao')"
									class="
										input-group-addon
										glyphicon glyphicon-remove
										pointer
									"
									title="Cancelar"
								></span>

								<select class="form-control" v-model="situacao">
									<option
										v-for="sit in situacaoList"
										:key="sit.id"
										:value="sit.id"
									>
										{{ sit.situacao }}
									</option>
								</select>

								<label class="control-label">{{
									erroList.situacao
								}}</label>
							</div>
						</dl>
					</div>
				</div>

				<div class="panel panel-lg panel-default" v-if="turma">
					<div class="panel-heading">
						<div class="row">
							<div class="col-md-6">Páginas dessa turma</div>
							<div class="col-md-6 text-right">
								<a
									class="btn btn-primary"
									@click="getRespostas()"
								>
									Recuperar respostas
								</a>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<app-paginas
							:idTurma="turma.id"
							:inscritos="matriculaList.length"
						/>
					</div>
				</div>

				<div class="panel panel-lg panel-default" v-if="turma">
					<div class="panel-heading">
						<div class="row">
							<div class="col-md-6">
								Alunas inscritas - {{ matriculaList.length }}
							</div>
							<div class="col-md-6 text-right">
								<div class="btn-group">
									<router-link
										:to="{
											name: 'formularioMatricula',
											params: {
												idEvento:
													$route.params.idEvento,
											},
										}"
									>
										<a class="btn btn-primary">
											Realizar inscrição
										</a>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<table class="table table-hover">
							<thead>
								<tr>
									<th>Nome</th>
									<th>Ações</th>
								</tr>
							</thead>
							<tbody>
								<tr
									class="pointer"
									v-for="(matricula, index) in matriculaList"
									:key="matricula.id"
								>
									<td
										@click="
											$router.push(
												'/usuario/' +
													matricula.usuario.id
											)
										"
									>
										{{ matricula.usuario.nome }}
									</td>
									<td>
										<span
											@click="removeMatricula(index)"
											class="
												glyphicon glyphicon-remove
												pointer
											"
											title="Cancelar Matricula"
										></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import Paginas from "@/components/pagina/Paginas.vue";

export default {
	data() {
		return {
			turma: null,
			matriculaList: [],
			situacaoList: [],
			erroList: [],
			submiting: false,

			nome: null,
			observacao: null,
			dataInicio: null,
			horaInicio: null,
			situacao: null,

			editName: false,
			editObservacao: false,
			editInicio: false,
			editSituacao: false,
		};
	},
	methods: {
		getTurma() {
			this.$http
				.get(`turma/${this.$route.params.idEvento}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.turma = data;

					this.nome = this.turma.nome;
					this.observacao = this.turma.observacao;
					this.dataInicio = moment(this.turma.inicio).format(
						"YYYY-MM-DD"
					);
					this.horaInicio = moment(this.turma.inicio).format("HH:MM");
					this.situacao = this.turma.situacao.id;
				});
		},
		getMatriculasTurma() {
			this.$http
				.get(`matricula/${this.$route.params.idEvento}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.matriculaList = data;
				});
		},
		getSituacaoTurma() {
			this.$http
				.get("situacao")
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.situacaoList = data;
				});
		},
		getRespostas() {
			this.$http
				.get(`resposta/${this.turma.id}`, { responseType: "text/csv" })
				.then((response) => {
					var fileURL = window.URL.createObjectURL(
						new Blob([response.data], { type: "text/csv" })
					);
					var fURL = document.createElement("a");

					fURL.href = fileURL;
					fURL.setAttribute("download", "respostas.csv");
					document.body.appendChild(fURL);

					fURL.click();
				});
		},
		removeMatricula(index) {
			var idMatricula = this.matriculaList[index].id;
			this.$http.delete(`matricula/${idMatricula}`).then(() => {
				this.matriculaList.splice(index, 1);
			});
		},

		ModificarTurma(json, field) {
			this.$http
				.put("turma", json)
				.then(
					(response) => {
						this.turma = response.body;
						this.erroList = [];
						if (field) this.toggleEdit(field);
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "há campos incorreto no formulário!",
							};
							this.$emit("showMessage", returnMessage);
							this.erroList = error.body;
						}
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},

		toggleEdit(field) {
			this[field] = !this[field];
			if (!this.editName) this.nome = this.turma.nome;
			if (!this.editObservacao) this.observacao = this.turma.observacao;
			if (!this.editInicio) {
				this.dataInicio = moment(this.turma.inicio).format(
					"YYYY-MM-DD"
				);
				this.horaInicio = moment(this.turma.inicio).format("HH:MM");
			}
			if (!this.editSituacao) this.situacao = this.turma.situacao.id;
		},

		mudaNome() {
			this.submiting = true;
			let json = {
				idTurma: this.$route.params.idEvento,
				nome: this.nome,
			};
			this.ModificarTurma(json, "editName");
		},
		mudaObservacao() {
			this.submiting = true;
			let json = {
				idTurma: this.$route.params.idEvento,
				observacao: this.observacao,
			};
			this.ModificarTurma(json, "editObservacao");
		},
		mudaInicio() {
			this.submiting = true;
			let json = {
				idTurma: this.$route.params.idEvento,
				inicio: `${this.dataInicio}T${this.horaInicio}`,
			};
			this.ModificarTurma(json, "editInicio");
		},
		mudaSituacao() {
			this.submiting = true;
			let json = {
				idTurma: this.$route.params.idEvento,
				idSituacao: this.situacao,
			};
			this.ModificarTurma(json, "editSituacao");
		},
	},
	created() {
		this.getTurma();
		this.getMatriculasTurma();
		this.getSituacaoTurma();
	},
	components: {
		appPaginas: Paginas,
	},
};
</script>

<style>
</style>