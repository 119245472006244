<template>
	<form>
		<fieldset>
			<legend>DEFININDO O M1</legend>

			<div class="form-group">
				<label>Como é o caráter dele?</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="m10"
					@change="addToPoll('m10', m10)"
				></textarea>
			</div>

			<legend>QUEM É ESSE HOMEM? COMO É A VIDA DELE?</legend>

			<label class="comando">FÍSICO:</label>
			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Alto"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Alto</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Baixo"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Baixo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Magro"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Magro</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Gordinho"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Gordinho</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Musculoso"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Musculoso</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Cabelo loiro"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Cabelo loiro</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Cabelo castanho"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Cabelo castanho</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Careca"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Careca</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pele branca"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Pele branca</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pele parda"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Pele parda</span>
						</label>
					</div>
				</div>

				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pele negra"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Pele negra</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Com tatuagem"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Com tatuagem</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Sem tatuagem"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Sem tatuagem</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Com piercing"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Com piercing</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Sem piercing"
								v-model="m11"
								@change="addToPoll('m11', m11)"
							/>
							<span>Sem piercing</span>
						</label>
					</div>

					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m11Extra[index]"
							@change="addToPoll('m11Extra', m11Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">ESTADO CIVIL / FILHOS:</label>
			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Solteiro"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Solteiro</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Divorciado"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Divorciado</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Viúvo"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Viúvo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Com filhos"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Com filhos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Sem filhos"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Sem filhos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Quer ter filhos"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Quer ter filhos</span>
						</label>
					</div>
				</div>

				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Não quer ter filhos"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Não quer ter filhos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Indiferente"
								v-model="m12"
								@change="addToPoll('m12', m12)"
							/>
							<span>Indiferente</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m12Extra[index]"
							@change="addToPoll('m12Extra', m12Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">PERFIL DE COMPORTAMENTO:</label>
			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Executor"
								v-model="m13"
								@change="addToPoll('m13', m13)"
							/>
							<span>Executor</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Comunicador"
								v-model="m13"
								@change="addToPoll('m13', m13)"
							/>
							<span>Comunicador</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Planejador"
								v-model="m13"
								@change="addToPoll('m13', m13)"
							/>
							<span>Planejador</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Analista"
								v-model="m13"
								@change="addToPoll('m13', m13)"
							/>
							<span>Analista</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m13Extra[index]"
							@change="addToPoll('m13Extra', m13Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">BEBIDA E CIGARRO:</label>
			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Não bebe"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Não bebe </span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Bebe socialmente"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Bebe socialmente</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Bebe frequentemente"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Bebe frequentemente</span>
						</label>
					</div>

					<div clas="form-group" v-if="showBebida">
						Bebida preferida:
						<input
							class="form-control"
							type="text"
							v-model="m1bebida"
							@change="addToPoll('m1bebida', m1bebida)"
						/>
					</div>

					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Não fuma"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Não fuma</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Fuma socialmente"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Fuma socialmente</span>
						</label>
					</div>

					<div clas="form-group" v-if="showCigarro">
						Cigarro preferido:
						<input
							class="form-control"
							type="text"
							v-model="m1cigarro"
							@change="addToPoll('m1cigarro', m1cigarro)"
						/>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Não usa drogas"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Não usa drogas</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Usa drogas socialmente"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Usa drogas socialmente</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Usa drogas frequentemente"
								v-model="m14"
								@change="addToPoll('m14', m14)"
							/>
							<span>Usa drogas frequentemente</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m14Extra[index]"
							@change="addToPoll('m14Extra', m14Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">ROTINA DE ALIMENTAÇÃO:</label>
			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Carnívoro"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Carnívoro</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Vegetariano"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Vegetariano</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Vegano"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Vegano</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Dieta regrada"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Dieta regrada</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Dieta balanceada"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Dieta balanceada</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Indiferente"
								v-model="m15"
								@change="addToPoll('m15', m15)"
							/>
							<span>Indiferente</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m15Extra[index]"
							@change="addToPoll('m15Extra', m15Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">ROTINA DE EXERCÍCIOS FÍSICOS:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Faz atividade física ao ar livre"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span>Faz atividade física ao ar livre</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pratica esportes"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span>Pratica esportes</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Faz musculação"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span>Faz musculação</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Faz cross fit"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span>Faz cross fit</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Participa de corridas de rua"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span>Participa de corridas de rua</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Não faz atividade física"
								v-model="m16"
								@change="addToPoll('m16', m16)"
							/>
							<span> Não faz atividade física </span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m16Extra[index]"
							@change="addToPoll('m16Extra', m16Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">MORADIA:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora na mesma cidade"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora na mesma cidade</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora em cidade próxima"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora em cidade próxima</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora em outro estado"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora em outro estado</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora em outro país"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora em outro país</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora sozinho"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora sozinho</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Mora na praia"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora na praia</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Mora no campo"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora no campo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Mora com os pais"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora com os pais</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Mora com os filhos"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora com os filhos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Mora com amigos"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Mora com amigos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Aberto a mudar de cidade"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Aberto a mudar de cidade</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Ama a cidade que mora"
								v-model="m17"
								@change="addToPoll('m17', m17)"
							/>
							<span>Ama a cidade que mora</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m17Extra[index]"
							@change="addToPoll('m17Extra', m17Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">HORAS VAGAS / FINAL DE SEMANA:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Fica em casa"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Fica em casa</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Assiste Televisão"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Assiste Televisão</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Assiste Netflix"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Assiste Netflix</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Vai ao cinema"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Vai ao cinema</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Joga online"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Joga online</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Estuda"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Estuda</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Lê"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Lê</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Sai com os amigos"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Sai com os amigos</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Vai para balada"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Vai para balada</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Vai para barzinho"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Vai para barzinho</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Faz churrasco"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Faz churrasco</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Viaja"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Viaja</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pratica esporte"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Pratica esporte</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Vai pra academia"
								v-model="m18"
								@change="addToPoll('m18', m18)"
							/>
							<span>Vai pra academia</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m18Extra[index]"
							@change="addToPoll('m18Extra', m18Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">EMOCIONAL / COMPORTAMENTAL</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Paciente"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Paciente</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Compreensivo"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Compreensivo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Bem resolvido"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Bem resolvido</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Pró-ativo"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Pró-ativo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Seguro"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Seguro</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Equilibrado"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Equilibrado</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Lida com os problemas com leveza"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Lida com os problemas com leveza</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Tem controle emocional"
								v-model="m19"
								@change="addToPoll('m19', m19)"
							/>
							<span>Tem controle emocional</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m19Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m19Extra[index]"
							@change="addToPoll('m19Extra', m19Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">TRABALHO:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Funcionário público"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Funcionário público</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Funcionário CLT"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Funcionário CLT</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Empreendedor"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Empreendedor</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Autônomo"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Autônomo</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Famoso"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Famoso</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Político"
								v-model="m110"
								@change="addToPoll('m110', m110)"
							/>
							<span>Político</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m110Extra[index]"
							@change="addToPoll('m110Extra', m110Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">RELAÇÃO COM O DINHEIRO:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Poupador"
								v-model="m111"
								@change="addToPoll('m111', m111)"
							/>
							<span>Poupador</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Gastador"
								v-model="m111"
								@change="addToPoll('m111', m111)"
							/>
							<span>Gastador</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Bem resolvido financeiramente"
								v-model="m111"
								@change="addToPoll('m111', m111)"
							/>
							<span>Bem resolvido financeiramente</span>
						</label>
					</div>
					<div class="checkbox">
						<label
							><input
								type="checkbox"
								value="Defende que dinheiro foi feito para gastar"
								v-model="m111"
								@change="addToPoll('m111', m111)"
							/>
							<span>
								Defende que dinheiro foi feito para gastar
							</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Gosta de cuidar das finanças"
								v-model="m111"
								@change="addToPoll('m111', m111)"
							/>
							<span>Gosta de cuidar das finanças</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m111Extra[index]"
							@change="addToPoll('m111Extra', m111Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<label class="comando">RELAÇÃO COM O TRABALHO:</label>

			<div class="row">
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Ama o trabalho"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span>Ama o trabalho</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Trabalha em horário comercial"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span>Trabalha em horário comercial</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Trabalha viajando"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span>Trabalha viajando</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Trabalha aos finais de semana"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span>Trabalha aos finais de semana</span>
						</label>
					</div>
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Tem horário flexível"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span>Tem horário flexível</span>
						</label>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="checkbox">
						<label>
							<input
								type="checkbox"
								value="Tira férias frequentemente"
								v-model="m112"
								@change="addToPoll('m112', m112)"
							/>
							<span> Tira férias frequentemente</span>
						</label>
					</div>
					<label>extra</label>
					<div
						clas="form-group"
						v-for="(extra, index) in m11Extra"
						:key="index"
					>
						<input
							type="text"
							class="form-control"
							maxlength="500"
							v-model.lazy="m112Extra[index]"
							@change="addToPoll('m112Extra', m112Extra)"
						/>
					</div>
				</div>
			</div>

			<hr />

			<div class="form-group">
				<label>COMO É O RELACIONAMENTO QUE VOCÊ QUER TER?</label>
				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="m113"
					@change="addToPoll('m113', m113)"
				></textarea>
			</div>

			<div class="form-group">
				<label>
					O QUE VOCÊ NÃO ACEITA DE JEITO NENHUM? O QUE É INEGOCIÁVEL?
				</label>

				<textarea
					class="form-control ta-G"
					maxlength="1000"
					v-model="m114"
					@change="addToPoll('m114', m114)"
				>
				</textarea>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			m10: [],
			m11: [],
			m12: [],
			m13: [],
			m14: [],
			m15: [],
			m16: [],
			m17: [],
			m18: [],
			m19: [],
			m110: [],
			m111: [],
			m112: [],
			m113: [],
			m114: [],

			m11Extra: [],
			m12Extra: [],
			m13Extra: [],
			m14Extra: [],
			m15Extra: [],
			m16Extra: [],
			m17Extra: [],
			m18Extra: [],
			m19Extra: [],
			m110Extra: [],
			m111Extra: [],
			m112Extra: [],
			m113Extra: [],
			m114Extra: [],

			m1bebida: "",
			m1cigarro: "",
		};
	},

	methods: {},
	computed: {
		showCigarro() {
			return this.m14.includes("Fuma socialmente");
		},
		showBebida() {
			return (
				this.m14.includes("Bebe socialmente") ||
				this.m14.includes("Bebe frequentemente")
			);
		},
	},
	watch: {},
	created() {
		//for (let i = 0; i < 6; i++) this.addExtra();
		for (var k in this._data) {
			if (k.startsWith("m1")) this.carregaRespostas(k);

			if (k.includes("Extra")) {
				this[k].push("");
				this[k].push("");
			}
		}
	},
};
</script>

<style scoped>
input.form-control {
	margin-bottom: 14px;
}

.comando {
	margin: 10px 0;
}

.checkbox {
	margin-top: -4px;
}

.checkbox label {
	padding-left: 35px;
}

input[type="checkbox"] {
	display: none;
}

span::before {
	content: "";
	background: url("~@/assets/check-circle.svg");
	background-position: center;
	background-size: contain;
	width: 30px;
	height: 30px;
	position: absolute;
	left: -3px;
	top: -7px;

	transform: scale(0) rotateZ(180deg);
	transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
}

input[type="checkbox"]:checked + span::before {
	transform: scale(1) rotateZ(0deg);
}

span::after {
	content: "";
	border: 2px solid #d34758;
	width: 24px;
	height: 24px;
	position: absolute;
	left: 0px;
	top: -4px;
	border-radius: 50%;
}
</style>