<template>
	<div class="pagina">
		<div class="container">
			<div class="panel-group">
				<div class="panel panel-lg panel-default">
					<div class="panel-heading">
						<div class="row">
							<div class="col-md-6">Usuários cadastrados</div>
							<div class="col-md-6 text-right">
								<div class="btn-group">
									<router-link to="/usuario/novo">
										<a class="btn btn-primary"
											>Cadastrar usuario</a
										>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<app-usuarios
							v-if="usuarioList"
							:usuarioList="usuarioList"
							@selecao="toUsuario($event)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ListaUsuarios from "@/components/usuario/Usuarios.vue";
export default {
	data() {
		return { usuarioList: [] };
	},
	methods: {
		toUsuario(idUsuario) {
			this.$router.push("/usuario/" + idUsuario);
		},
		getUsuarios() {
			this.$http
				.get("usuario")
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.usuarioList = data;
				});
		},
	},
	created() {
		this.getUsuarios();
	},
	components: {
		appUsuarios: ListaUsuarios,
	},
};
</script>