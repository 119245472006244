<template>
	<div class="pagina">
		<div class="container">
			<div class="panel panel-default">
				<div class="panel-body">
					<form>
						<fieldset>
							<legend>Cadastro de evento</legend>

							<div
								:class="[
									'form-group',
									{ 'has-error': erroList.nome },
								]"
							>
								<label>Nome</label>
								<input
									class="form-control"
									type="text"
									maxlength="50"
									v-model="turma.nome"
								/>
								<label class="control-label">{{
									erroList.nome
								}}</label>
							</div>

							<label>Início</label>
							<div
								:class="[
									'row form-group',
									{ 'has-error': erroList.inicio },
								]"
							>
								<div class="col-md-4">
									<label>Data</label>
									<input
										class="form-control"
										type="date"
										v-model="dataInicio"
									/>
								</div>
								<div class="col-md-4">
									<label>Horário</label>
									<input
										class="form-control"
										type="time"
										maxlength="255"
										v-model="horaInicio"
									/>
								</div>
								<label class="control-label">{{
									erroList.inicio
								}}</label>
							</div>

							<div
								:class="[
									'form-group',
									{ 'has-error': erroList.observacao },
								]"
							>
								<label>Observacao</label>
								<textarea
									class="form-control"
									maxlength="501"
									v-model="turma.observacao"
								></textarea>
								<label class="control-label">{{
									erroList.observacao
								}}</label>
							</div>

							<div class="form-group text-center">
								<button
									:class="['btn btn-primary']"
									:disabled="submiting"
									@click.prevent="submit"
								>
									Cadastrar evento
								</button>
								<router-link to="/evento">
									<a class="btn btn-primary">cancelar</a>
								</router-link>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			submiting: false,
			erroList: [],
			horaInicio: null,
			dataInicio: null,
			turma: {},
		};
	},
	methods: {
		submit() {
			this.submiting = true;
			this.turma.inicio = `${this.dataInicio}T${this.horaInicio}`;

			this.$http
				.post("turma", this.turma)
				.then((response) => {
					return response.json();
				})
				.then(
					(data) => {
						var returnMessage = {
							type: "alert-success",
							mensagem: "Evento cadastrado com sucesso!",
						};
						this.$emit("showMessage", returnMessage);
						this.$router.push("/evento/" + data);
						this.submiting = false;
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: "há campos incorreto no formulário!",
							};
							this.$emit("showMessage", returnMessage);
							this.erroList = error.body;
							this.submiting = false;
						}
					}
				);
		},
	},
};
</script>