<template>
	<div class="pagina">
		<div class="container">
			<div class="panel-group">
				<div class="panel panel-default">
					<div class="panel-heading">Individualmente</div>
					<div class="panel-body">
						<form>
							<fieldset>
								<div class="form-group">
									<label>Nome / e-mail / telefone</label>
									<input
										type="text"
										class="form-control"
										v-model="filtroAluna"
									/>
								</div>

								<div class="form-group text-center">
									<button
										:class="['btn btn-primary']"
										:disabled="submiting"
										@click.prevent="pesquisaAlunas()"
									>
										Pesquisar alunas
									</button>
								</div>

								<app-usuarios
									v-if="usuarioList"
									:usuarioList="usuarioList"
									@selecao="matriculaAluna($event)"
								/>
							</fieldset>
						</form>
					</div>
				</div>

				<div class="panel panel-default">
					<div class="panel-heading">Em lote</div>
					<div class="panel-body">
						<a
							class="glyphicon glyphicon-download-alt"
							href="/modelo.csv"
							target="_blank"
							>Arquivo de modelo</a
						>
						<hr />
						<app-form-anexo
							@fileChanged="csv = $event"
							:fileName="csv !== null ? csv.nome : ''"
							:erroList="erroList.certificado"
						/>

						<div class="form-group text-center">
							<button
								:class="['btn btn-primary']"
								:disabled="submiting"
								@click.prevent="submit"
							>
								Realizar inscrição
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ListaUsuarios from "@/components/usuario/Usuarios.vue";
import FormAnexo from "@/components/anexo/Formulario.vue";

export default {
	data() {
		return {
			erroList: [],
			csv: null,
			filtroAluna: "",
			usuarioList: null,
			submiting: false,
		};
	},
	methods: {
		pesquisaAlunas() {
			if (this.filtroAluna) var params = { filtro: this.filtroAluna };

			this.$http
				.get("usuario", {
					params: params,
				})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.usuarioList = data;
				});
		},

		matriculaAluna(idUsuario) {
			var matricula = {
				turma: this.$route.params.idEvento,
				usuario: idUsuario,
			};

			this.$http.post("matricula", matricula).then((response) => {
				let quantidade = response.body;
				var returnMessage = {
					type: "alert-success",
					mensagem:
						quantidade == 1
							? "aluna matriculadas!"
							: "aluna já estava matriculada!",
				};
				this.$emit("showMessage", returnMessage);
				this.csv = null;
			});
		},

		submit() {
			this.submiting = true;

			var formData = new FormData();
			if (this.csv)
				formData.append("arquivo", this.csv.arquivo, this.csv.nome);

			this.$http
				.post(`matriculaLote/${this.$route.params.idEvento}`, formData)
				.then(
					(response) => {
						let quantidade = response.body;
						var returnMessage = {
							type: "alert-success",
							mensagem: `${quantidade} alunas matriculadas!`,
						};
						this.$emit("showMessage", returnMessage);
						this.csv = null;
					},
					(error) => {
						if (error.status === 400) {
							var returnMessage = {
								type: "alert-danger",
								mensagem: error.body.anexo,
							};
							this.$emit("showMessage", returnMessage);
							this.erroList = error.body;
						}
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},
	},

	components: {
		appFormAnexo: FormAnexo,
		appUsuarios: ListaUsuarios,
	},
};
</script>

<style>
</style>