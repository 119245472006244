import Vue from "vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import App from "./App.vue";
import { routes } from "./routes";
import { LoginMixin } from "@/components/login/LoginMixin.js";

import ReturnMessage from "@/components/ReturnMessage.vue";

import moment from "moment";
moment.locale("pt-BR");

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_ROOT_API;

Vue.http.interceptors.push((request, next) => {
	if (localStorage.token)
		request.headers.set("Authorization", "Bearer " + localStorage.token);
	next();
});

Vue.http.interceptors.push(function (request, next) {
	next(function (response) {
		if (response.status == 500) {
			var returnMessage = {
				type: "alert-danger",
				mensagem: "Infelizmente tivemos um grave erro no servidor! Entre em contato com os admisnitradores para solução",
			};
			this.$emit("showMessage", returnMessage);
		}
	});
});

Vue.use(VueRouter);
const router = new VueRouter({
	mode: "history",
	routes
});

Vue.component("appMessage", ReturnMessage);

Vue.mixin(LoginMixin);


Vue.filter("telefone", function (phone) {
	return phone ? phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "";
});

Vue.filter("zap", function (phone) {
	return "http://wa.me/55" + phone;
});

Vue.filter("dataHora", function (value) {
	return value == null ? "--" : moment(value).format("LLL");
});

Vue.filter("data", function (value) {
	return moment(value).format("LL");
});

Vue.filter("money", function (value, moeda) {
	return new Intl.NumberFormat("pt-BR", { style: "currency", currency: moeda }).format(value);
});

new Vue({
	el: "#app",
	router,
	components: { App },
	template: "<App/>",
});
