<template>
	<table class="table table-hover">
		<thead>
			<tr>
				<th>Ordem</th>
				<th>Página</th>
				<th title="pelo menos uma resposta na página">respondido?</th>
				<th>Ações</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(pagina, index) in paginaList" :key="pagina.id">
				<td>{{ pagina.numero }}</td>
				<td>{{ pagina.nome }}</td>
				<td>{{ porcentagem(pagina.quantidade) }}%</td>
				<td>
					<button
						v-if="pagina.liberada == 0"
						:class="['btn btn-primary']"
						:disabled="submiting"
						@click="liberaPagina(index)"
					>
						Liberar Página
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	props: ["idTurma", "inscritos"],
	data() {
		return {
			paginaList: [],
			submiting: false,
			polling: null,
		};
	},
	methods: {
		porcentagem(respostas) {
			return respostas == 0 || respostas == undefined
				? 0
				: ((respostas / this.inscritos) * 100).toFixed(1);
		},
		getPaginas() {
			this.$http
				.get(`pagina/${this.idTurma}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.paginaList = data;
				});
		},
		liberaPagina(iPaginas) {
			this.submiting = true;
			let pagina = this.paginaList[iPaginas];
			let json = {
				id: pagina.id,
				liberada: 1,
			};
			this.$http
				.put("pagina", json)
				.then(
					(response) => {
						this.paginaList[iPaginas].numero = response.body;
						this.paginaList[iPaginas].liberada = json.liberada;

						var pag = this.paginaList.splice(iPaginas, 1);

						this.paginaList.splice(
							Number(response.body) - 1,
							0,
							pag[0]
						);
					},
					() => {
						var returnMessage = {
							type: "alert-danger",
							mensagem: "Algo deu errado",
						};
						this.$parent.$emit("showMessage", returnMessage);
					}
				)
				.then(() => {
					this.submiting = false;
				});
		},

		getContagemRespostas(pagina) {
			if (pagina.liberada == 1) {
				this.$http
					.get(
						`quantidadeResposta/${this.idTurma}/${pagina.prefixoRepostas}`
					)
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						pagina.quantidade = data;
					});

				this.$forceUpdate();
			} else pagina.quantidade = 0;
		},
	},
	created() {
		this.getPaginas();
		this.polling = setInterval(() => {
			for (var k in this.paginaList) {
				this.getContagemRespostas(this.paginaList[k]);
			}
		}, 5000);
	},
	beforeDestroy() {
		clearInterval(this.polling);
	},
};
</script>

<style>
</style>