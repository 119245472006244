<template>
	<div v-if="started" class="countup" id="countup1">
		<div class="timeItem">
			<span class="timeel">{{ dia }}</span>
			<span class="timeel ref">dias</span>
		</div>
		<div class="timeItem">
			<span class="timeel">{{ hora }}</span>
			<span class="timeel ref">horas</span>
		</div>
		<div class="timeItem">
			<span class="timeel">{{ min }}</span>
			<span class="timeel ref">minutos</span>
		</div>
		<div class="timeItem">
			<span class="timeel">{{ seg }}</span>
			<span class="timeel ref">segundos</span>
		</div>
	</div>
</template>

<script>
export default {
	props: ["inicio"],
	data() {
		return {
			polling: null,
			started: false,
			dia: 0,
			hora: 0,
			min: 0,
			seg: 0,
		};
	},
	computed: {
		passFieldType() {
			return this.showSenha ? "text" : "password";
		},
	},

	methods: {
		startContador() {
			this.started = true;
			var diferenca =
				new Date(this.inicio).getTime() - new Date().getTime();

			if (diferenca < 0) {
				clearInterval(this.polling);
				this.started = false;
			}

			this.dia = Math.floor(diferenca / (1000 * 60 * 60 * 24));
			this.hora = Math.floor(
				(diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			this.min = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
			this.seg = Math.floor((diferenca % (1000 * 60)) / 1000);
		},
	},
	created() {
		this.startContador();
		this.polling = setInterval(() => {
			this.startContador();
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.polling);
	},
};
</script>

<style>
.countup {
	display: inline;
}

.timeItem {
	display: inline-block;
}
.countup .timeel {
	display: inline-block;
	padding: 8px 5px 2px 5px;
	background: #d34758;
	margin: 2px 0;
	color: white;
	min-width: 2.6rem;
	margin-left: 13px;
	border-radius: 10px 0 0 10px;
}
.countup span.ref {
	border-radius: 0 10px 10px 0;
	margin-left: 0;
	background: #494e5f;
	color: white;
}
</style>