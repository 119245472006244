<template>
	<div :class="[{ 'has-error': erroList }]">
		<div :class="['drop-zone', { dragOver: dragInto }]">
			<label
				class=""
				@dragenter="dragInto++"
				@dragleave="dragInto--"
				@drop.prevent="dropFiles"
				@dragover.prevent
			>
				<img src="@/assets/Down.svg" alt="upload" title="upload" />
				<p class="text-center">Selecione o arquivo ou araste-o aqui!</p>
				<input
					id="idFile"
					class="inv"
					type="file"
					name="folder"
					accept=".csv"
					@change="changeInputFiles"
				/>
			</label>
		</div>

		<template v-if="fileName">
			<label>Anexo selecionado</label>
			<ul class="list-group">
				<li class="list-group-item">
					{{ fileName }}
					<span
						class="glyphicon glyphicon-trash pointer"
						title="remover anexo"
						@click.prevent="excluirArquivo()"
					></span>
				</li>
			</ul>
		</template>
		<label class="control-label">{{ erroList }}</label>
	</div>
</template>

<script>
export default {
	props: ["erroList", "fileName"],
	data() {
		return { dragInto: 0, arquivo: null };
	},
	methods: {
		processFiles(files) {
			var arquivo = { arquivo: files[0], nome: files[0].name };
			this.arquivo = arquivo;

			this.$emit("fileChanged", arquivo);
		},
		excluirArquivo() {
			this.arquivo = null;
			document.getElementById("idFile").value = "";
			this.$emit("fileChanged", null);
		},
		dropFiles(event) {
			this.dragInto = 0;
			this.processFiles(event.dataTransfer.files);
		},
		changeInputFiles(event) {
			this.processFiles(event.target.files);
		},
	},
	computed: {},
	mounted() {},
};
</script>

<style scoped>
.drop-zone {
	background-color: #df7280;
	outline: 2px dashed #a12138;
	outline-offset: -10px;
	text-align: center;
}

.has-error > .drop-zone {
	outline: 2px dashed #a94442;
}

.drop-zone label {
	padding-top: 25px;
	width: 100%;
}

.drop-zone.dragOver {
	background-color: #d6eaef;
	outline: 2px dashed #001ebf;
	outline-offset: -15px;
}

.inv {
	display: none;
}
</style>
