export const QuestaoMixin = {
	data() {
		return {
			timer: null,
			respostas: {}
		};
	},
	methods: {
		sendRespostas() {
			this.$http
				.post(
					`resposta/${this.$route.params.idEvento}`,
					this.respostas
				)
				.then(() => {
					this.respostas = {};
				});
		},
		carregaRespostas(pergunta, unica) {
			this.$http
				//TODO - adicionar caso de erro
				.get(
					`resposta/${this.$route.params.idEvento}/${pergunta}`
				)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (Object.keys(data).length)
						this[pergunta] = unica ? data[0] : data;
				});
		},
		addToPoll(questao, respostas) {
			clearTimeout(this.timer);
			this.respostas[questao] = respostas;
			this.timer = setTimeout(this.sendRespostas, 10000);
		},

	}
}