<template>
	<header>
		<app-navbar></app-navbar>
	</header>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
	components: { appNavbar: NavBar },
};
</script>
