<template>
	<div class="pagina">
		<div class="container">
			<div class="panel-group">
				<div class="panel panel-lg panel-default">
					<div class="panel-heading">
						<div class="row">
							<div class="col-md-6">Eventos</div>
							<div class="col-md-6 text-right">
								<div class="btn-group">
									<router-link to="/evento/novo">
										<a class="btn btn-primary"
											>Cadastrar evento</a
										>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body">
						<table class="table table-hover">
							<thead>
								<tr>
									<th>Nome</th>
									<th>início</th>
									<th>Situacao</th>
								</tr>
							</thead>
							<tbody>
								<tr
									class="pointer"
									v-for="turma in turmaList"
									:key="turma.id"
									@click="toEvento(turma.id)"
								>
									<td>{{ turma.nome }}</td>
									<td>{{ turma.inicio | dataHora }}</td>
									<td>{{ turma.situacao.situacao }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return { turmaList: [] };
	},
	methods: {
		getTurmas() {
			this.$http
				.get("turma")
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					this.turmaList = data;
				});
		},
		toEvento(idEvento) {
			this.$router.push(`/evento/${idEvento}`);
		},
	},
	created() {
		this.getTurmas();
	},
};
</script>