<template>
	<div class="range-wrap">
		<output class="bubble" :style="{ left: tootipPos }">{{ range }}</output>
		<input
			type="range"
			class="range"
			:min="min"
			:max="max"
			v-model="range"
			@input="$emit('input', range)"
		/>
	</div>
</template>

<script>
export default {
	props: {
		min: {
			default: 0,
		},
		max: {
			default: 10,
		},
		value: {
			default: 0,
		},
	},
	data() {
		return { range: 0 };
	},
	computed: {
		tootipPos() {
			const newVal =
				((this.range - this.min) * 100) / (this.max - this.min);
			return `calc(${newVal}% + (${-1 - newVal * 0.2}px))`;
		},
	},
	watch: {
		value() {
			this.range = this.value;
		},
	},
	created() {
		this.range = this.value;
	},
};
</script>

<style scoped>
input[type=range] {
  width: 100%;
  margin: -1px 0 5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}

/*Chrome*/
input[type=range]::-webkit-slider-runnable-track {
  background: #d34758;
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -6.5px;
  width: 16px;
  height: 18px;
  background: #ffffff;
  border-radius: 7px;
  border: 0.1px solid #000000;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #d44b5c;
}

/** FF*/
input[type="range"]::-moz-range-progress {
	background-color: #d34758;
}
input[type="range"]::-moz-range-track {
	background-color: #494e5f59;
}
/* IE*/
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 7.5px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #d24354;
  border: 0;
  border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
  background: #d34758;
  border: 0;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  width: 16px;
  height: 18px;
  background: #ffffff;
  border: 0.1px solid #000000;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #d34758;
}
input[type=range]:focus::-ms-fill-upper {
  background: #d44b5c;
}




.bubble {
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;
	padding: 2px 5px;
	min-width: 20px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	border: 1px solid #d34758;
	background: #d34758;
	top: -8px;
}

.bubble::before {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: inherit;
	transform: translate(-50%);
}
</style>