<template>
	<div class="pagina-luisa">
		<div class="container">
			<div v-if="isAdm" class="panel panel-default">
				<div class="panel-body">
					<a class="btn btn-primary" @click="getRespostas()">
						Recuperar respostas
					</a>
				</div>
			</div>

			<div class="panel panel-default">
				<div class="panel-body">
					<form>
						<fieldset>
							<legend>Conte-nos a sua experiência</legend>
							<div :class="['form-group']">
								<label>
									Sua opinião é muito importante para nós. Por
									que valeu a pena participar da Imersão?
								</label>
								<textarea
									class="form-control ta-G"
									maxlength="1000"
									v-model="opiniao"
								></textarea>
							</div>

							<div class="form-group text-center">
								<button
									:class="[
										'btn',
										'btn-primary',
										{ disabled: submiting },
									]"
									@click.prevent="sendOpiniao()"
								>
									Enviar experiência
								</button>
							</div>

							<hr />

							<div class="form-group text-center">
								<label>
									Para realizar uma avaliação completa,
									responda a nossa pesquisa de satisfação,
									clicando abaixo.
								</label>
								<br />
								<a
									class="btn btn-primary"
									href="https://luizavono.typeform.com/to/vwyoztKG"
									target="_blank"
									>Avaliação completa</a
								>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			submiting: false,
			opiniao: "",
		};
	},
	methods: {
		sendOpiniao() {
			this.submiting = true;
			this.$http
				.post("pesquisa", { opiniao: this.opiniao })
				.then(() => {
					var returnMessage = {
						type: "alert-success",
						mensagem: "Obrigado por compartilhar sua experiência!",
					};
					this.$emit("showMessage", returnMessage);
					this.opiniao = "";
				})
				.then(() => {
					this.submiting = false;
				});
		},
		getRespostas() {
			this.$http
				.get("pesquisa", { responseType: "text/csv" })
				.then((response) => {
					var fileURL = window.URL.createObjectURL(
						new Blob([response.data], { type: "text/csv" })
					);
					var fURL = document.createElement("a");

					fURL.href = fileURL;
					fURL.setAttribute("download", "respostas.csv");
					document.body.appendChild(fURL);

					fURL.click();
				});
		},
	},
};
</script>

<style scoped>
button,
a {
	width: 100%;
	margin-top: 20px;
}

@media (min-width: 1025px) {
	.panel {
		max-width: 550px;
	}
}

@media (min-width: 1400px) {
	.panel {
		max-width: 650px;
	}
}
</style>