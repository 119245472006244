<template>
	<form>
		<fieldset>
			<legend>DÍNAMO DO RELACIONAMENTO</legend>

			<h2>
				DE 0 A 10, DÊ UMA NOTA PARA O QUANTO VOCÊ SE IDENTIFICA COM CADA
				FRASE:
			</h2>

			<ol>
				<li>
					<label>
						Sou muito velha/nova para encontrar a felicidade no
						amor.
					</label>
				</li>
				<app-slider
					:value="crenca1"
					@input="setValue('crenca1', $event)"
				/>
				<hr />

				<li>
					<label>
						Não tenho tempo para me dedicar a um relacionamento.
					</label>
				</li>
				<app-slider
					:value="crenca2"
					@input="setValue('crenca2', $event)"
				/>
				<hr />

				<li>
					<label>Eu não mereço ser feliz em um relacionamento.</label>
				</li>
				<app-slider
					:value="crenca3"
					@input="setValue('crenca3', $event)"
				/>
				<hr />

				<li>
					<label
						>Meus sonhos são impossíveis de serem alcançados.</label
					>
				</li>
				<app-slider
					:value="crenca4"
					@input="setValue('crenca4', $event)"
				/>
				<hr />

				<li>
					<label>Não levo jeito para flertar.</label>
				</li>
				<app-slider
					:value="crenca5"
					@input="setValue('crenca5', $event)"
				/>
				<hr />

				<li>
					<label>
						As coisas precisam ser perfeitas para darem certo.
					</label>
				</li>
				<app-slider
					:value="crenca6"
					@input="setValue('crenca6', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu não sou o suficiente (boa, educada, magra, jovem,
						inteligente, rica).
					</label>
				</li>
				<app-slider
					:value="crenca7"
					@input="setValue('crenca7', $event)"
				/>
				<hr />

				<li>
					<label>
						Se eu deixar as pessoas realmente me conhecerem, elas
						não vão gostar de mim.
					</label>
				</li>
				<app-slider
					:value="crenca8"
					@input="setValue('crenca8', $event)"
				/>
				<hr />

				<li>
					<label>
						Se eu tomar a iniciativa na conquista vou ser rejeitada.
					</label>
				</li>
				<app-slider
					:value="crenca9"
					@input="setValue('crenca9', $event)"
				/>
				<hr />

				<li>
					<label>Se eu falar o que penso, vou ser rejeitada.</label>
				</li>
				<app-slider
					:value="crenca10"
					@input="setValue('crenca10', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu tenho que ficar em algum relacionamento, mesmo que
						ruim, porque eunão consigo ficar sozinha.
					</label>
				</li>
				<app-slider
					:value="crenca11"
					@input="setValue('crenca11', $event)"
				/>
				<hr />

				<li>
					<label
						>Eu não posso ser feliz no relacionamento até que minha
						carreira esteja estabelecida.
					</label>
				</li>
				<app-slider
					:value="crenca12"
					@input="setValue('crenca12', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu preciso fazer mais e mais para ser digna de um amor.
					</label>
				</li>
				<app-slider
					:value="crenca3"
					@input="setValue('crenca13', $event)"
				/>
				<hr />

				<li>
					<label>Eu não mereço o amor.</label>
				</li>
				<app-slider
					:value="crenca4"
					@input="setValue('crenca14', $event)"
				/>
				<hr />

				<li>
					<label>
						É melhor eu não ser muito feliz ou a queda será maior.
					</label>
				</li>
				<app-slider
					:value="crenca15"
					@input="setValue('crenca15', $event)"
				/>
				<hr />

				<li>
					<label>As coisas nunca dão certo para mim.</label>
				</li>
				<app-slider
					:value="crenca16"
					@input="setValue('crenca16', $event)"
				/>
				<hr />

				<li>
					<label>
						A culpa não é minha que as coisas só deem errado.
					</label>
				</li>
				<app-slider
					:value="crenca17"
					@input="setValue('crenca17', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu sempre vou ter que lutar, enquanto outros têm tudo
						mais fácil.
					</label>
				</li>
				<app-slider
					:value="crenca18"
					@input="setValue('crenca18', $event)"
				/>
				<hr />

				<li>
					<label>Eu tenho o dedo podre.</label>
				</li>
				<app-slider
					:value="crenca19"
					@input="setValue('crenca19', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu sou responsável pela felicidade de outras pessoas, e
						elas são responsáveis por minha.
					</label>
				</li>
				<app-slider
					:value="crenca20"
					@input="setValue('crenca20', $event)"
				/>
				<hr />

				<li>
					<label>É muito difícil encontrar o meu M1.</label>
				</li>
				<app-slider
					:value="crenca21"
					@input="setValue('crenca21', $event)"
				/>
				<hr />

				<li>
					<label>Os homens são todos iguais.</label>
				</li>
				<app-slider
					:value="crenca22"
					@input="setValue('crenca22', $event)"
				/>
				<hr />

				<li>
					<label>Todo homem trai e mente.</label>
				</li>
				<app-slider
					:value="crenca23"
					@input="setValue('crenca23', $event)"
				/>
				<hr />

				<li>
					<label>Homens preferem relacionamentos fáceis.</label>
				</li>
				<app-slider
					:value="crenca24"
					@input="setValue('crenca24', $event)"
				/>
				<hr />

				<li>
					<label>Homens só querem sexo.</label>
				</li>
				<app-slider
					:value="crenca25"
					@input="setValue('crenca25', $event)"
				/>
				<hr />

				<li>
					<label>
						Se ele não falou comigo, não está interessado.
					</label>
				</li>
				<app-slider
					:value="crenca26"
					@input="setValue('crenca26', $event)"
				/>
				<hr />

				<li>
					<label>
						Se eu tomar a iniciativa, ele vai me considerar fácil.
					</label>
				</li>
				<app-slider
					:value="crenca27"
					@input="setValue('crenca27', $event)"
				/>
				<hr />

				<li>
					<label>Não tem homens interessantes em aplicativos.</label>
				</li>
				<app-slider
					:value="crenca28"
					@input="setValue('crenca28', $event)"
				/>
				<hr />

				<li>
					<label>
						Eu tenho que estar sempre linda e perfeita para
						encontrar com ele.
					</label>
				</li>
				<app-slider
					:value="crenca29"
					@input="setValue('crenca29', $event)"
				/>
				<hr />

				<li>
					<label>
						Se eu for pra cama com ele, ele não vai querer nada
						sério.
					</label>
				</li>
				<app-slider
					:value="crenca30"
					@input="setValue('crenca30', $event)"
				/>
				<hr />

				<li>
					<label>
						Se eu perguntar se ele quer um relacionamento, vai
						parecer desespero.
					</label>
				</li>
				<app-slider
					:value="crenca31"
					@input="setValue('crenca31', $event)"
				/>
				<hr />

				<li>
					<label>
						Já tenho filho, será mais difícil achar alguém.
					</label>
				</li>
				<app-slider
					:value="crenca32"
					@input="setValue('crenca32', $event)"
				/>
				<hr />
			</ol>

			<div class="panel panel-lg panel-default">
				<div class="panel-heading">REESCREVENDO CRENÇAS</div>
				<div class="panel-body">
					<div class="form-group">
						<label> 1 - </label>
						<textarea
							class="form-control ta-P"
							maxlength="5000"
							placeholder="Crença 1"
							v-model="crenca33"
							@change="addToPoll('crenca33', crenca33)"
						>
						</textarea>
					</div>

					<div class="form-group">
						<label> 2 - </label>
						<textarea
							class="form-control ta-P"
							maxlength="5000"
							placeholder="Crença 2"
							v-model="crenca34"
							@change="addToPoll('crenca34', crenca34)"
						>
						</textarea>
					</div>

					<div class="form-group">
						<label> 3 - </label>
						<textarea
							class="form-control ta-P"
							maxlength="5000"
							placeholder="Crença 3"
							v-model="crenca35"
							@change="addToPoll('crenca35', crenca35)"
						>
						</textarea>
					</div>

					<div class="form-group">
						<label> 4 - </label>
						<textarea
							class="form-control ta-P"
							maxlength="5000"
							placeholder="Crença 4"
							v-model="crenca36"
							@change="addToPoll('crenca36', crenca36)"
						>
						</textarea>
					</div>

					<div class="form-group">
						<label> 5 - </label>
						<textarea
							class="form-control ta-P"
							maxlength="5000"
							placeholder="Crença 5"
							v-model="crenca37"
							@change="addToPoll('crenca37', crenca37)"
						>
						</textarea>
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { QuestaoMixin } from "@/components/ferramenta/QuestaoMixin.js";
import Slider from "@/components/UI/Slider.vue";

export default {
	mixins: [QuestaoMixin],
	data() {
		return {
			crenca1: 0,
			crenca2: 0,
			crenca3: 0,
			crenca4: 0,
			crenca5: 0,
			crenca6: 0,
			crenca7: 0,
			crenca8: 0,
			crenca9: 0,
			crenca10: 0,
			crenca11: 0,
			crenca12: 0,
			crenca13: 0,
			crenca14: 0,
			crenca15: 0,
			crenca16: 0,
			crenca17: 0,
			crenca18: 0,
			crenca19: 0,
			crenca20: 0,
			crenca21: 0,
			crenca22: 0,
			crenca23: 0,
			crenca24: 0,
			crenca25: 0,
			crenca26: 0,
			crenca27: 0,
			crenca28: 0,
			crenca29: 0,
			crenca30: 0,
			crenca31: 0,
			crenca32: 0,

			crenca33: "",
			crenca34: "",
			crenca35: "",
			crenca36: "",
			crenca37: "",
		};
	},
	methods: {
		setValue(prop, value) {
			this[prop] = value;
			this.addToPoll(prop, this[prop]);
		},
	},
	created() {
		for (var k in this._data)
			if (k.startsWith("crenca")) this.carregaRespostas(k, true);
	},
	components: {
		appSlider: Slider,
	},
};
</script>

<style>
</style>