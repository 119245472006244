import Vue from "vue";
const state = Vue.observable({ token: null });
//const state = { token: null };

export const LoginMixin = {
	created() {
		state.token = localStorage.token;
	},
	computed: {
		logedUser() {
			var jwt = this.getJWT();
			return jwt ? jwt.sub : "";
		},
		isLogedIn() {
			var jwt = this.getJWT();
			return jwt ? jwt.iss === process.env.VUE_APP_TOKEN_ISS : false;
		},
		isAdm() {
			var jwt = this.getJWT();
			return jwt.role == "Administrador";
		}
	},
	methods: {
		getJWT() {
			if (state.token)
				return JSON.parse(atob(state.token.split(".")[1]));
			return false;
		},
		login(token) {
			state.token = token;
			localStorage.token = token;
			this.$router.push("/apresentacao");
			//location.reload();
		},
		logout() {
			state.token = null;
			localStorage.removeItem("token");
			this.$router.push("/login");
			//location.reload();
		}
	}
};
